import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  span {
    width: 160px;
    padding: 8px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    opacity: 0;
    visibility: hidden;
    background-color:  ${(props) => props.theme.primary.dark};;
    transition: opacity 0.4s;
    position: absolute;
    bottom: calc(100% + 12px);
    left: 50%;
    transform: translate(-50%);
    color: white;

    &::before {
      content: "";
      border-style: solid;
      border-color:  ${(props) => props.theme.primary.dark}; transparent;
      border-width: 6px 6px 0 6px;
      top: 100%;
      position: absolute;
      left: 49.5%;
      transform: translate(-50%);
    }
  }
  &&:hover span {
    opacity: 1;
    visibility: visible;
  }
`;
