import { monetaryValues } from "../Constants/Colors";

export const formatCurrency = (value, currency, minorUnit = 2) => {
  value = value / 10 ** minorUnit;

  const valueToFormat = value || 0;
  return valueToFormat.toLocaleString("pt-BR", {
    style: "currency",
    currency: currency || "BRL",
  });
};

//UNIDADE PARA CENTAVOS
export const convertToCents = (value) => {
  let newValue = stringToDecimal(value);
  return Number(newValue) * 100;
};

export const simpleConvertToCents = (value) => {
  let newValue = value.replace(/\D/g, "");

  return Number(newValue);
};

//CENTAVOS PARA UNIDADE
export const convertToUnity = (value) => {
  let newValue = stringToDecimal(value);
  return Number(newValue) / 100;
};

//STRING PARA DECIMAL
export const stringToDecimal = (value) => {
  if (!value) {
    return 0.0;
  }
  let newValue = String(value);
  if (newValue.length >= 8) {
    newValue = newValue.replace(/\./g, "");
    newValue = newValue.replace(/,/g, ".");
  } else {
    newValue = newValue.replace(/,/g, ".");
  }
  return Number(newValue).toFixed(2);
};

export const formatCurrencyColor = (value) => {
  if (value > 0) {
    return monetaryValues.positive;
  }

  if (value < 0) {
    return monetaryValues.negative;
  }

  return monetaryValues.neutral;
};

export const formatCurrencyType = (value) => {
  if (value >= 0) {
    return "credit";
  }

  if (value < 0) {
    return "debit";
  }
};

export const formatDocument = (value, type) => {
  if (!value || !type) {
    return "";
  }
  switch (type) {
    case "CPF":
      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
    case "CNPJ":
      return value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        "$1 $2 $3/$4-$5"
      );

    default:
      return value;
  }
};

export const formatPhone = (value, type) => {
  if (!value || !type) {
    return "";
  }
  switch (type) {
    case "CEL":
      return value.replace(/(\d{2})(\d{5})(\d{4})/, "$1 $2-$3");

    default:
      return value;
  }
};

export const orderArrayByName = (a, b) =>
  a.name > b.name ? 1 : b.name > a.name ? -1 : 0;
