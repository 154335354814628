import { FormEvent, useState } from "react";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format, isAfter, isBefore, sub } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { Stack } from "@mui/material";
import {
  ButtonsWrapper,
  DatePickerContainer,
  DatePickerField,
  Field,
  Form,
} from "../styles";
import { useToast } from "../../../../../Hooks/toast";
import { useFilter } from "../../../../../Hooks/useFilter";

interface PixTotalsFilterProps {
  tableName: string;
  setPageToFirst: () => void;
}

export const PixTotalsFilter = ({
  tableName,
  setPageToFirst,
}: PixTotalsFilterProps) => {
  const { getFilterObj, handleApplyFilters, handleRemoveFilters } = useFilter();

  const filterObj = getFilterObj(tableName) as any;

  const { addToast } = useToast();

  const [startDate, setStartDate] = useState<Date | null>(() => {
    return filterObj?.startDate !== null &&
      filterObj?.startDate !== undefined &&
      filterObj?.startDate !== ""
      ? new Date(filterObj.startDate.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    return filterObj?.endDate !== null &&
      filterObj?.endDate !== undefined &&
      filterObj?.endDate !== ""
      ? new Date(filterObj.endDate.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });

  const handleCustomStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleCustomEndDateChange = (date) => {
    setEndDate(date);
  };

  const handleDeleteFilters = () => {
    if (Object.keys(filterObj).length === 0) {
      addToast({
        title: "Nenhum filtro aplicado.",
        description: "Não tem filtro aplicado para ser removido.",
        type: "info",
      });
      return;
    }

    handleRemoveFilters(tableName, {
      startDate: format(new Date(), "yyyy-MM-dd"),
      endDate: format(new Date(), "yyyy-MM-dd"),
    });
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();

    const queryParams: any = {
      startDate: startDate === null ? "" : format(startDate, "yyyy-MM-dd"),
      endDate: endDate === null ? "" : format(endDate, "yyyy-MM-dd"),
    };

    const hasValue = Object.keys(queryParams).some((key) => !!queryParams[key]);

    //verifica se algum filtro foi aplicado
    if (!hasValue) {
      addToast({
        type: "info",
        title: "Nenhum filtro foi aplicado!",
        description: "Por favor insira algum parâmetro.",
      });

      return;
    }

    //Verifica se as datas estão em um formato válido
    const regex = /^\d{4}-([0]\d|1[0-2])-([0-2]\d|3[01])$/;
    if (
      !regex.test(queryParams.startDate) ||
      isBefore(
        new Date(queryParams.startDate),
        sub(new Date(), { years: 1 })
      ) ||
      isAfter(
        new Date(queryParams.startDate.replace(/-/g, "/").replace(/T.+/, "")),
        new Date()
      ) ||
      !regex.test(queryParams.endDate) ||
      isBefore(
        new Date(queryParams.endDate.replace(/-/g, "/").replace(/T.+/, "")),
        sub(new Date(), { years: 1 })
      ) ||
      isAfter(
        new Date(queryParams.endDate.replace(/-/g, "/").replace(/T.+/, "")),
        new Date()
      )
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Por favor insira uma data válida.",
      });

      return;
    }

    //Verifica se a data inicial não é maior que a data final
    if (
      isAfter(
        new Date(queryParams.startDate.replace(/-/g, "/").replace(/T.+/, "")),
        new Date(queryParams.endDate.replace(/-/g, "/").replace(/T.+/, ""))
      )
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Data inicial não pode ser maior que data final.",
      });

      return;
    }

    handleApplyFilters(tableName, queryParams);
    setPageToFirst();
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <Stack
            direction="row"
            style={{ gap: "2.2rem" }}
            alignItems={"center"}
          >
            <Field>
              <DesktopDatePicker
                label="Data inicial"
                value={startDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomStartDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
            <Field>
              <DesktopDatePicker
                label="Data final"
                value={endDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomEndDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
          </Stack>
        </LocalizationProvider>
      </DatePickerContainer>
      <ButtonsWrapper>
        <button type="submit">Aplicar filtros</button>
        <button type="button" onClick={handleDeleteFilters}>
          Remover filtros
        </button>
      </ButtonsWrapper>
    </Form>
  );
};
