import { ArrowRight } from "@mui/icons-material";
import { useHistory } from "react-router";
import { ButtonComponent } from "./styles";

export const LinkButton = ({ children, icon, path, search = "", ...rest }) => {
  const history = useHistory();
  return (
    <ButtonComponent
      onClick={() => {
        history.push({
          pathname: path,
          search: search,
        });
      }}
      {...rest}
    >
      <p>
        {icon}
        {children}
      </p>
      {/* <p>subtutke</p> */}
      <ArrowRight />
    </ButtonComponent>
  );
};
