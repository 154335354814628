import { TextField } from "@mui/material";
import styled from "styled-components";

export const Form = styled.form`
  width: 105%;
`;

export const FlexDiv = styled.div`
  display: flex;
  height: 100%;

  align-items: center;
  justify-content: space-between;

  margin-bottom: 1.5rem;
`;
export const FlexDivCustom = styled.div`
  display: flex;

  align-items: center;
  gap: 2.2rem;

  margin-bottom: 1.5rem;
`;

export const Field = styled.div`
  display: flex;
  width: 310px;
  flex-direction: column;
  gap: 0.2rem;

  align-items: flex-start;

  fieldset {
    border-color: #b7b7b7;
    &:hover {
      border: 1px solid #404057;
    }
  }
`;

export const StyledTextField = styled(TextField)`
  && {
    width: 100%;

    border-radius: 0.3rem;

    color: #666;
  }
`;
export const DatePickerField = styled(TextField)`
  && {
    position: relative;
    border: black;
    width: 100%;
  }
`;

export const DatePickerContainer = styled.div`
  position: relative;
  margin-bottom: 1.5rem;
  p {
    color: #666;
  }
  fieldset {
    border-color: #b7b7b7;
    &:hover {
      border: 1px solid rgb(255 255 255 / 100%);
    }
  }

  input {
    background: none;

    color: #666;
  }

  svg {
    color: #666;
  }
`;

export const ButtonsWrapper = styled.div`
  button[type="submit"] {
    margin-right: 1.5rem;

    font-weight: bold;
    font-size: 0.9em;
    background: #b79568;
    color: white;

    border: none;
    border-radius: 0.3rem;

    padding: 1rem 0.5rem;

    &:hover,
    &:focus,
    &:active {
      filter: brightness(0.9);
    }
  }
  button[type="button"] {
    font-weight: bold;
    font-size: 0.9em;

    background: #d0d0d0;
    color: #645c5c;

    border: none;
    border-radius: 0.3rem;

    padding: 1rem 0.5rem;

    &:hover,
    &:focus,
    &:active {
      filter: brightness(0.9);
    }
  }
`;
