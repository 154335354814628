import styled from "styled-components";

export const DataContent = styled.div`
  margin: 0 auto;
  justify-content: center;
  display: flex;
  text-align: left;
  padding: 40px;
  width: 100%;
  gap: 5px;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  flex-direction: column;
  margin-bottom: 20px;
  hr {
    margin-top: 10px;
    height: 1.6px;
    width: auto;
    background-color: #cecece;
    border: 0px;
  }
  h3 {
    font-size: 22px;
    font-weight: 500;
  }
  p {
    margin-top: 20px;
    font-size: 16px;
    font-weight: 700;
  }
  span {
    color: #999;
    font-size: 16px;
  }
  div {
    margin-top: 20px;
    display: flex;
    align-self: center;
    gap: 20px;
    button {
      width: 200px;
    }
  }
`;
