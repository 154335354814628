import { Hidden } from "@mui/material";
import { useEffect } from "react";
import {
  useAccountBalanceData,
  useAccountData,
  useTransactionsData,
} from "../../../Hooks";
import DesktopDashboard from "./DesktopDashboard";
import { Loading } from "../../../Components/Atoms";
import { normalizeTransactionsData } from "../../../Hooks/Normalizers";

export const Dashboard = ({}) => {
  const {
    state: accountData,
    handleGET: fetchAccountGET,
    loading: accountLoading,
  } = useAccountData();
  const {
    state: transactionsData,
    handleGET: fetchTransactionsGET,
    loading: transactionsLoading,
  } = useTransactionsData();
  const {
    state: accountBalanceData,
    handleGET: fetchAccountBalanceGET,
    loading: balanceLoading,
  } = useAccountBalanceData();

  useEffect(() => {
    fetchAccountGET();
    fetchAccountBalanceGET();
    fetchTransactionsGET({ limit: 5 });
  }, []);

  return (
    <>
      {accountLoading.GET || transactionsLoading.GET || balanceLoading.GET ? (
        <Loading />
      ) : (
        <>
          <Hidden mdDown>
            <DesktopDashboard
              accountData={accountData}
              transactions={transactionsData}
              topNotification={
                !!accountBalanceData &&
                parseInt(accountBalanceData.balance.replace(/[^0-9]/g, "")) ===
                  0 ? (
                  <span>
                    Você ainda não tem<strong> saldo </strong>em sua conta! Faça
                    um depósito já.<strong> Depositar.</strong>
                  </span>
                ) : (
                  parseInt(accountBalanceData.balance.replace(/[^0-9]/g, "")) <
                    0 && (
                    <span>
                      Seu saldo no momento está negativo
                      <strong>façá já um depósito</strong>em sua conta!
                      <strong> Depositar.</strong>
                    </span>
                  )
                )
              }
            />
          </Hidden>
        </>
      )}
    </>
  );
};
