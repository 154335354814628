import { useEffect, useState } from "react";
import { Container, Option } from "./styles";

export const SelectOptionButtons = ({
  refreshOptionSelected,
  options,
  defaultOption = "",
  isSelectable,
  width,
  ...rest
}) => {
  const [option, setOption] = useState(defaultOption);

  useEffect(() => {
    refreshOptionSelected(option);
  }, [option]);

  return (
    <Container>
      {options.map((value, index) =>
        isSelectable ? (
          <Option
            aria-label="selectable-options"
            key={index}
            style={{ width: width, height: "2em" }}
            isSelected={option === value.key}
            disabled={option === value.key}
            type="button"
            value={value.title}
            onClick={() => {
              setOption(value.key);
            }}
          />
        ) : (
          <Option
            key={index}
            style={{ width: width, height: "2em" }}
            isSelected={option === value.key}
            disabled={option === value.key}
            type="button"
            value={value.title}
            onClick={() => {
              refreshOptionSelected(value.key);
            }}
          />
        )
      )}
    </Container>
  );
};
