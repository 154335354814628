import styled, { keyframes, css } from "styled-components";
import { darken } from "polished";
import { FiLoader } from "react-icons/fi";

const rotate = keyframes`
    to {
        transform: rotate(360deg);
    }
`;

interface IProps {
  action?: string;
}

export const Container = styled.div<IProps>`
  span {
    font-size: 15px;
    color: white;
  }
  button {
    border-radius: 4px;
    height: calc(2.125rem + 2px);
    border: 0;
    padding: 0 16px;
    color: white;
    font-weight: 500;
    transition: 0.3s;
    cursor: pointer;
    width: 100%;

    ${(props) =>
      props.action === "cancel"
        ? css`
            background-color: white;
            border: 2px solid ${props.theme.feedback.error};
            color: ${props.theme.feedback.error};
          `
        : props.action === "return"
        ? css`
            background-color: white;
            border: 2px solid ${props.theme.primary.original};
            color: ${props.theme.primary.original};
          `
        : props.action === "whatsapp"
        ? css`
            background-color: white;
            border: 2px solid ${darken(0.2, props.theme.feedback.success)};
            color: ${darken(0.2, props.theme.feedback.success)};
          `
        : css`
            background-color: ${(props) => props.theme.primary.original};
          `}

    :hover:enabled {
      ${(props) =>
        !props.action &&
        css`
          background-color: ${(props) => props.theme.primary.dark};
        `}

      :hover {
        transform: scale(1.05);
        transition: all 0.2s ease-in-out;
      }
    }
    :disabled {
      cursor: not-allowed;
      background-color: #999;
    }
  }
`;

export const Loader = styled(FiLoader)`
  margin-left: 10px;
  margin: 0 auto;
  font-size: 15px;
  vertical-align: middle;
  color: white;
  animation: ${rotate} 2.5s infinite;
`;
