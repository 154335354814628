export const productList = [
  {
    key: 1,
    description: "Emissão de boletos",
    id: 5,
    value: "NULL",
    type: "billet",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 2,
    description: "Master",
    id: 3,
    value: "NULL",
    type: "debitlinkmaster",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 3,
    description: "Visa",
    id: 3,
    value: "NULL",
    type: "debitlinkvisa",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 4,
    description: "Elo",
    id: 3,
    value: "NULL",
    type: "debitlinkelo",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 5,
    description: "Master",
    id: 4,
    value: 1,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 6,
    description: "Master",
    id: 4,
    value: 2,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 7,
    description: "Master",
    id: 4,
    value: 3,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 8,
    description: "Master",
    id: 4,
    value: 4,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 9,
    description: "Master",
    id: 4,
    value: 5,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 10,
    description: "Master",
    id: 4,
    value: 6,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 11,
    description: "Master",
    id: 4,
    value: 7,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 12,
    description: "Master",
    id: 4,
    value: 8,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 13,
    description: "Master",
    id: 4,
    value: 9,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 14,
    description: "Master",
    id: 4,
    value: 10,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 15,
    description: "Master",
    id: 4,
    value: 11,
    type: "master",
    any1: 5,
    any2: "NULL",
  },
  {
    key: 16,
    description: "Master",
    id: 4,
    value: 12,
    type: "master",
    any1: 5,
    any2: 0,
  },
  {
    key: 17,
    description: "Visa",
    id: 4,
    value: 1,
    type: "visa",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 18,
    description: "Visa",
    id: 4,
    value: 2,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 19,
    description: "Visa",
    id: 4,
    value: 3,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 20,
    description: "Visa",
    id: 4,
    value: 4,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 21,
    description: "Visa",
    id: 4,
    value: 5,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 22,
    description: "Visa",
    id: 4,
    value: 6,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 23,
    description: "Visa",
    id: 4,
    value: 7,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 24,
    description: "Visa",
    id: 4,
    value: 8,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 25,
    description: "Visa",
    id: 4,
    value: 9,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 26,
    description: "Visa",
    id: 4,
    value: 10,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 27,
    description: "Visa",
    id: 4,
    value: 11,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 28,
    description: "Visa",
    id: 4,
    value: 12,
    type: "visa",
    any1: 17,
    any2: "NULL",
  },
  {
    key: 29,
    description: "Elo",
    id: 4,
    value: 1,
    type: "elo",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 30,
    description: "Elo",
    id: 4,
    value: 2,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 31,
    description: "Elo",
    id: 4,
    value: 3,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 32,
    description: "Elo",
    id: 4,
    value: 4,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 33,
    description: "Elo",
    id: 4,
    value: 5,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 34,
    description: "Elo",
    id: 4,
    value: 6,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 35,
    description: "Elo",
    id: 4,
    value: 7,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 36,
    description: "Elo",
    id: 4,
    value: 8,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 37,
    description: "Elo",
    id: 4,
    value: 9,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 38,
    description: "Elo",
    id: 4,
    value: 10,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 39,
    description: "Elo",
    id: 4,
    value: 11,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 40,
    description: "Elo",
    id: 4,
    value: 12,
    type: "elo",
    any1: 29,
    any2: "NULL",
  },
  {
    key: 41,
    description: "Hiper",
    id: 4,
    value: 1,
    type: "hiper",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 42,
    description: "Hiper",
    id: 4,
    value: 2,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 43,
    description: "Hiper",
    id: 4,
    value: 3,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 44,
    description: "Hiper",
    id: 4,
    value: 4,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 45,
    description: "Hiper",
    id: 4,
    value: 5,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 46,
    description: "Hiper",
    id: 4,
    value: 6,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 47,
    description: "Hiper",
    id: 4,
    value: 7,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 48,
    description: "Hiper",
    id: 4,
    value: 8,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 49,
    description: "Hiper",
    id: 4,
    value: 9,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 50,
    description: "Hiper",
    id: 4,
    value: 10,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 51,
    description: "Hiper",
    id: 4,
    value: 11,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 52,
    description: "Hiper",
    id: 4,
    value: 12,
    type: "hiper",
    any1: 41,
    any2: "NULL",
  },
  {
    key: 53,
    description: "Amex",
    id: 4,
    value: 1,
    type: "amex",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 54,
    description: "Amex",
    id: 4,
    value: 2,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 55,
    description: "Amex",
    id: 4,
    value: 3,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 56,
    description: "Amex",
    id: 4,
    value: 4,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 57,
    description: "Amex",
    id: 4,
    value: 5,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 58,
    description: "Amex",
    id: 4,
    value: 6,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 59,
    description: "Amex",
    id: 4,
    value: 7,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 60,
    description: "Amex",
    id: 4,
    value: 8,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 61,
    description: "Amex",
    id: 4,
    value: 9,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 62,
    description: "Amex",
    id: 4,
    value: 10,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 63,
    description: "Amex",
    id: 4,
    value: 11,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 64,
    description: "Amex",
    id: 4,
    value: 12,
    type: "amex",
    any1: 53,
    any2: "NULL",
  },
  {
    key: 67,
    description: "Saque Cartão",
    id: 5,
    value: "NULL",
    type: "withdrawcard",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 68,
    description: "Compras Cartão",
    id: 5,
    value: "NULL",
    type: "purchasescard",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 69,
    description: "Ted",
    id: 5,
    value: "NULL",
    type: "ted",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 70,
    description: "Doc",
    id: 5,
    value: "NULL",
    type: "doc",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 71,
    description: "Pagamento de Contas",
    id: 5,
    value: "NULL",
    type: "billpayment",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 72,
    description: "Emissão de Cartão Correio",
    id: 5,
    value: "NULL",
    type: "creditcardemissionNorth",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 73,
    description: "Recarga de Celular",
    id: 5,
    value: "NULL",
    type: "rechargephone",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 75,
    description: "P2P",
    id: 5,
    value: "NULL",
    type: "p2p",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 76,
    description: "Qr Code",
    id: 5,
    value: "NULL",
    type: "qrcode",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 79,
    description: "Master",
    id: 8,
    value: 1,
    type: "cashinmaster",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 80,
    description: "Master",
    id: 8,
    value: 2,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 81,
    description: "Master",
    id: 8,
    value: 3,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 82,
    description: "Master",
    id: 8,
    value: 4,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 83,
    description: "Master",
    id: 8,
    value: 5,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 84,
    description: "Master",
    id: 8,
    value: 6,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 85,
    description: "Master",
    id: 8,
    value: 7,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 86,
    description: "Master",
    id: 8,
    value: 8,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 87,
    description: "Master",
    id: 8,
    value: 9,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 88,
    description: "Master",
    id: 8,
    value: 10,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 89,
    description: "Master",
    id: 8,
    value: 11,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 90,
    description: "Master",
    id: 8,
    value: 12,
    type: "cashinmaster",
    any1: 79,
    any2: "NULL",
  },
  {
    key: 91,
    description: "Visa",
    id: 8,
    value: 1,
    type: "cashinvisa",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 92,
    description: "Visa",
    id: 8,
    value: 2,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 93,
    description: "Visa",
    id: 8,
    value: 3,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 94,
    description: "Visa",
    id: 8,
    value: 4,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 95,
    description: "Visa",
    id: 8,
    value: 5,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 96,
    description: "Visa",
    id: 8,
    value: 6,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 97,
    description: "Visa",
    id: 8,
    value: 7,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 98,
    description: "Visa",
    id: 8,
    value: 8,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 99,
    description: "Visa",
    id: 8,
    value: 9,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 100,
    description: "Visa",
    id: 8,
    value: 10,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 101,
    description: "Visa",
    id: 8,
    value: 11,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 102,
    description: "Visa",
    id: 8,
    value: 12,
    type: "cashinvisa",
    any1: 91,
    any2: "NULL",
  },
  {
    key: 103,
    description: "Elo",
    id: 8,
    value: 1,
    type: "cashinelo",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 104,
    description: "Elo",
    id: 8,
    value: 2,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 105,
    description: "Elo",
    id: 8,
    value: 3,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 106,
    description: "Elo",
    id: 8,
    value: 4,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 107,
    description: "Elo",
    id: 8,
    value: 5,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 108,
    description: "Elo",
    id: 8,
    value: 6,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 109,
    description: "Elo",
    id: 8,
    value: 7,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 110,
    description: "Elo",
    id: 8,
    value: 8,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 111,
    description: "Elo",
    id: 8,
    value: 9,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 112,
    description: "Elo",
    id: 8,
    value: 10,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 113,
    description: "Elo",
    id: 8,
    value: 11,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 114,
    description: "Elo",
    id: 8,
    value: 12,
    type: "cashinelo",
    any1: 103,
    any2: "NULL",
  },
  {
    key: 115,
    description: "Hiper",
    id: 8,
    value: 1,
    type: "cashinhiper",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 116,
    description: "Hiper",
    id: 8,
    value: 2,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 117,
    description: "Hiper",
    id: 8,
    value: 3,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 118,
    description: "Hiper",
    id: 8,
    value: 4,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 119,
    description: "Hiper",
    id: 8,
    value: 5,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 120,
    description: "Hiper",
    id: 8,
    value: 6,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 121,
    description: "Hiper",
    id: 8,
    value: 7,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 122,
    description: "Hiper",
    id: 8,
    value: 8,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 123,
    description: "Hiper",
    id: 8,
    value: 9,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 124,
    description: "Hiper",
    id: 8,
    value: 10,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 125,
    description: "Hiper",
    id: 8,
    value: 11,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 126,
    description: "Hiper",
    id: 8,
    value: 12,
    type: "cashinhiper",
    any1: 115,
    any2: "NULL",
  },
  {
    key: 127,
    description: "Amex",
    id: 8,
    value: 1,
    type: "cashinamex",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 128,
    description: "Amex",
    id: 8,
    value: 2,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 129,
    description: "Amex",
    id: 8,
    value: 3,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 130,
    description: "Amex",
    id: 8,
    value: 4,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 131,
    description: "Amex",
    id: 8,
    value: 5,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 132,
    description: "Amex",
    id: 8,
    value: 6,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 133,
    description: "Amex",
    id: 8,
    value: 7,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 134,
    description: "Amex",
    id: 8,
    value: 8,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 135,
    description: "Amex",
    id: 8,
    value: 9,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 136,
    description: "Amex",
    id: 8,
    value: 10,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 137,
    description: "Amex",
    id: 8,
    value: 11,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 138,
    description: "Amex",
    id: 8,
    value: 12,
    type: "cashinamex",
    any1: 127,
    any2: "NULL",
  },
  {
    key: 141,
    description: "Master",
    id: 7,
    value: 1,
    type: "debitocashinmaster",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 142,
    description: "Visa",
    id: 7,
    value: 1,
    type: "debitocashinvisa",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 143,
    description: "Elo",
    id: 7,
    value: 1,
    type: "debitocashinelo",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 144,
    description: "Ajuste",
    id: 5,
    value: "NULL",
    type: "ajuste",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 145,
    description: "Qr Code ELO",
    id: 5,
    value: "NULL",
    type: "qrcodeelo",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 146,
    description: "Cash In TED",
    id: 5,
    value: "NULL",
    type: "tedcashin",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 149,
    description: "Pix Pagar",
    id: 5,
    value: "NULL",
    type: "pixpagar",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 150,
    description: "Pix Receber",
    id: 5,
    value: "NULL",
    type: "pixreceber",
    any1: "NULL",
    any2: "NULL",
  },
  {
    key: 151,
    description: "Ted Receber",
    id: 5,
    value: "NULL",
    type: "tedreceber",
    any1: "NULL",
    any2: 1,
  },
  {
    key: 152,
    description: "Boleto Receber",
    id: 5,
    value: "NULL",
    type: "boletoreceber",
    any1: "NULL",
    any2: "NULL",
  },
];
