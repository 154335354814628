import { useState } from "react";

import {
  Button,
  Avatar,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import {
  KeyboardArrowDown,
  Person as PersonIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material/";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";

export const ProfileMenu = ({
  logout = () => {},
  isMobile = false,
  isDisabled = false,
  accountData = {},
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const theme = useTheme();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Avatar
        style={{
          backgroundColor: theme.primary.dark,
          cursor: "pointer",
          width: 35,
          height: 35,
        }}
        onClick={!isDisabled ? handleClick : null}
      >
        <PersonIcon style={{ fontSize: 20 }} />
      </Avatar>
      {!isMobile && (
        <Button
          color="primary"
          variant="text"
          onClick={handleClick}
          style={{
            color: theme.primary.dark,
            fontWeight: 700,
          }}
        >
          {accountData?.userName}
          <KeyboardArrowDown></KeyboardArrowDown>
        </Button>
      )}

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          style={{
            color: theme.primary.dark,
            padding: "10px 16px",
            borderBottom: `3px solid ${theme.primary.dark}`,
          }}
        >
          <ListItemIcon>
            <Avatar
              style={{
                backgroundColor: theme.primary.dark,
              }}
            >
              <PersonIcon />
            </Avatar>
          </ListItemIcon>
          <ListItemText
            primary={accountData?.userName}
            secondary={accountData?.userEmail}
            style={{
              color: theme.primary.dark,
              fontWeight: 700,
            }}
          />
        </MenuItem>
        <Link
          to="/perfil"
          style={{ textDecoration: "none !important", color: "inherit" }}
        >
          <MenuItem onClick={handleClose}>
            <ListItemIcon>
              <PersonIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Meu Perfil" />
          </MenuItem>
        </Link>
        <MenuItem onClick={logout}>
          <ListItemIcon>
            <ExitToAppIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Sair" />
        </MenuItem>
      </Menu>
    </div>
  );
};

ProfileMenu.propTypes = {};
