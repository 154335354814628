import axios from "axios";
import React from "react";
import { fetchAxios } from "../API/fetchAxios";
import { useValidateErrorResponse } from "./useValidateErrorResponse";
import { useSnackbar } from "notistack";
import { useToast } from "./toast";

const { CancelToken } = axios;

const defaultMethods = {
  GET: null,
  POST: null,
  PUT: null,
  PATCH: null,
  DELETE: null,
};

const useCallAxios = ({ context, responseType = "" }) => {
  const { addToast } = useToast();
  const [requestLoading, setRequestLoading] = React.useState({
    ...defaultMethods,
  });
  const [requestMessage, setRequestMessage] = React.useState({
    ...defaultMethods,
  });
  const [requestSuccess, setRequestSuccess] = React.useState({
    ...defaultMethods,
  });
  const [requestError, setRequestError] = React.useState({
    ...defaultMethods,
  });

  const [requestData, setRequestData] = React.useState({});
  const { validateError } = useValidateErrorResponse();

  const fetchAPI = ({
    verb,
    context,
    responseType = "Content-Type",
    queryParameters = null,
    id = null,
    afterID = null,
    body = null,
    noRefreshData,
    headers = {}
  }) => {
    if (!requestLoading[verb]) {
      setRequestMessage({ ...requestMessage, [verb]: null });
      setRequestError({ ...defaultMethods, [verb]: null });
      setRequestLoading({ ...defaultMethods, [verb]: true });
      setRequestSuccess({
        GET: null,
        POST: null,
        PUT: null,
        PATCH: null,
        DELETE: null,
      });

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        fetchAxios({
          cancelToken,
          verb,
          responseType,
          context,
          queryParameters,
          id,
          afterID,
          body,
          additionalHeaders: headers
        })
          .then((values) => {
            setRequestLoading({ ...requestLoading, [verb]: false });
            setRequestSuccess({ ...requestSuccess, [verb]: true });

            if (!noRefreshData) {
              setRequestData(values);
            }
            
            resolve();
          })
          .catch((err) => {
            !!err.response?.data &&
              // err.response?.status !== 404 &&
              addToast({
                title: err.response.data.information,
                description: err.response.data.description,
                type: err.response.data.isError ? "error" : "warning",
              });
            setRequestError({ ...requestError, [verb]: true });
            setRequestSuccess({ ...requestSuccess, [verb]: false });
            setRequestMessage({
              ...requestMessage,
              [verb]: err.response?.data,
            });
            setRequestLoading({ ...requestLoading, [verb]: false });
            if (!noRefreshData) {
              setRequestData({});
            }
            const errMessage = validateError(err?.response);

            reject(errMessage);
          });
      });
    }
  };

  const fetchGET = ({
    queryParameters = {},
    id = null,
    afterID = null,
    responseType = "content-type",
  }) => {
    const verb = "GET";
    fetchAPI({
      verb,
      responseType,
      context,
      queryParameters,
      id,
      afterID,
    });
  };

  const fetchPATCH = ({
    queryParameters = {},
    id = null,
    body = {},
    noRefreshData = false,
  }) => {
    const verb = "PATCH";
    fetchAPI({
      verb,
      context,
      queryParameters,
      id,
      body,
      noRefreshData,
    });
  };

  const fetchPOST = ({
    queryParameters = {},
    id = null,
    body = {},
    noRefreshData = false,
    headers = {}
  }) => {
    const verb = "POST";
    fetchAPI({
      verb,
      context,
      queryParameters,
      id,
      body,
      noRefreshData,
      headers
    });
  };

  const fetchPUT = ({
    queryParameters = {},
    context,
    id = null,
    body = {},
    noRefreshData = false,
  }) => {
    const verb = "PUT";
    fetchAPI({
      verb,
      context,
      queryParameters,
      id,
      body,
      noRefreshData,
    });
  };

  const fetchDELETE = ({
    queryParameters = {},
    id = null,
    body = {},
    noRefreshData,
  }) => {
    const verb = "DELETE";
    fetchAPI({
      verb,
      context,
      queryParameters,
      id,
      body,
      noRefreshData,
    });
  };

  const reset = () => {
    setRequestData({ ...defaultMethods });
    setRequestMessage({ ...defaultMethods });
    setRequestError({ ...defaultMethods });
    setRequestLoading({ ...defaultMethods });
    setRequestSuccess({ ...defaultMethods });
  };

  return {
    requestLoading,
    requestData,
    requestError,
    requestMessage,
    requestSuccess,
    fetchDELETE,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
    reset,
  };
};

export default useCallAxios;
