function ValidaCPF(c) {
  if (c.length !== 11) return false;

  if (
    c === "00000000000" ||
    c === "11111111111" ||
    c === "22222222222" ||
    c === "33333333333" ||
    c === "44444444444" ||
    c === "55555555555" ||
    c === "66666666666" ||
    c === "77777777777" ||
    c === "88888888888" ||
    c === "99999999999"
  )
    return false;

  var r;
  var s = 0;

  for (let i = 1; i <= 9; i++) s = s + parseInt(c[i - 1]) * (11 - i);

  r = (s * 10) % 11;

  if (r === 10 || r === 11) r = 0;

  if (r !== parseInt(c[9])) return false;

  s = 0;

  for (let i = 1; i <= 10; i++) s = s + parseInt(c[i - 1]) * (12 - i);

  r = (s * 10) % 11;

  if (r === 10 || r === 11) r = 0;

  if (r !== parseInt(c[10])) return false;

  return true;
}

function cnpjValidation(value) {
  if (value.length !== 14) return false;

  if (!value) return false;

  // Aceita receber o valor como string, número ou array com todos os dígitos
  const validTypes =
    typeof value === "string" ||
    Number.isInteger(value) ||
    Array.isArray(value);

  // Elimina valor em formato inválido
  if (!validTypes) return false;

  // Guarda um array com todos os dígitos do valor
  const match = value.toString().match(/\d/g);
  const numbers = Array.isArray(match) ? match.map(Number) : [];

  // Elimina inválidos com todos os dígitos iguais
  const items = [...new Set(numbers)];
  if (items.length === 1) return false;

  // Cálculo validador
  const calc = (x) => {
    const slice = numbers.slice(0, x);
    let factor = x - 7;
    let sum = 0;

    for (let i = x; i >= 1; i--) {
      const n = slice[x - i];
      sum += n * factor--;
      if (factor < 2) factor = 9;
    }

    const result = 11 - (sum % 11);

    return result > 9 ? 0 : result;
  };

  // Separa os 2 últimos dígitos de verificadores
  const digits = numbers.slice(12);

  // Valida 1o. dígito verificador
  const digit0 = calc(12);
  if (digit0 !== digits[0]) return false;

  // Valida 2o. dígito verificador
  const digit1 = calc(13);
  return digit1 === digits[1];
}

export const validateCPF_CNPJ = (value) => {
  value = value.replace(/[^\d]/g, "");
  if (value.length >= 14) {
    return cnpjValidation(value);
  } else {
    return ValidaCPF(value);
  }
};
