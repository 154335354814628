import { Container } from "./styles";
import { useCallback, useState } from "react";
import { PixPreview } from "../../../../Components/Molecules";
import { InitiatePixPayment } from "../../../../Hooks/UsePix";
import api from "../../../../Services/api";
import { FinalStep } from "../FinalStep";
import { useToast } from "../../../../Hooks/toast";
import { ModalLoader } from "../../../../Components/Atoms/ModalLoader";
import { FirstStepPixTransfer } from "../../../../Components/Molecules/PixTransferSteps/FirstStepPixTransfer";
import { SecondStepPixTransfer } from "../../../../Components/Molecules/PixTransferSteps/SecondStepPixTransfer";
import { useHistory } from "react-router-dom";

export const PixTransfer = () => {
  const {
    handlePOST: fetchInitiatePayment,
    state: initiatePaymentState,
    loading: initiatePaymentLoading,
    success: initiatePaymentSuccess,
  } = InitiatePixPayment();
  const history = useHistory();
  const { addToast } = useToast();
  const [step, setStep] = useState(0);
  const [linkValue, setLinkValue] = useState();
  const [loadingPixConfirmation, setLoadingPixConfirmation] = useState(false);
  const [confirmPaymentState, setConfirmPaymentState] = useState();

  const handleSetTransferAmount = (amount) => {
    setLinkValue(amount);
  };
  const handleSetStep = (step) => {
    setStep(step);
  };

  const resetStates = useCallback(() => {
    setStep(0);
  }, []);

  const handleFinishTransaction = useCallback(async (obj) => {
    const { validation, amount, pix_id } = obj;
    let payload = {
      validation,
      amount,
    };

    setLoadingPixConfirmation(true);
    await api
      .put(`/pix/${pix_id}`, payload)
      .then((res) => {
        setConfirmPaymentState(res.data);
        setStep(3);
      })
      .catch((err) => {
        addToast({
          description: err.response.data.description,
          title: err.response.data.information,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingPixConfirmation(false);
      });
  }, []);

  return (
    <Container>
      <ModalLoader
        open={loadingPixConfirmation}
        text={"Finalizando transferência"}
      />

      {step === 0 && (
        <FirstStepPixTransfer
          handleSetTransferAmount={handleSetTransferAmount}
          handleSetStep={handleSetStep}
        />
      )}
      {step === 1 && (
        <SecondStepPixTransfer
          transferAmount={linkValue}
          handleSetStep={handleSetStep}
          handleFetchInitialPayment={fetchInitiatePayment}
          loadingFetchInitialPayment={initiatePaymentLoading.POST}
          successFetchInitialPayment={initiatePaymentSuccess.POST}
        />
      )}
      {step === 2 && initiatePaymentSuccess.POST === true && (
        <PixPreview
          isLoading={loadingPixConfirmation}
          finishTransaction={(e) => handleFinishTransaction(e)}
          cancelOperation={() => history.push("/pix")}
          previewData={initiatePaymentState}
          amount={linkValue}
        />
      )}
      {step === 3 && !loadingPixConfirmation && (
        <FinalStep
          transactionStatus={!!confirmPaymentState}
          transactionData={confirmPaymentState}
          refreshSteps={() => {
            resetStates();
          }}
        />
      )}
    </Container>
  );
};
