import { Modal } from "@mui/material";
import styled, { css } from "styled-components";

interface IProps {
  isOpen: boolean;
}

export const Container = styled(Modal)`
  display: flex;
  width: 100vw;
  height: 100vh;
  border: 0;
  z-index: 10000;
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  h5 {
    color: white;
  }
`;
