import styled, { css } from "styled-components";
import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  secondaryColor?: string;
  isFilled: boolean;
  height?: string;
  width?: string;
  isErrored: boolean;
}

interface TooltipProps {
  isErrored?: boolean;
}

interface ISizes {
  width?: string;
  height?: string;
}

export const Container = styled.div<ISizes>`
  background: transparent;
  display: flex;
  margin: 8px 0px;
  flex-direction: column;
  flex-wrap: wrap;

  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}

  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css`
          height: 100%;
        `}
`;

export const Content = styled.div<ContainerProps>`
  background: transparent;
  color: #3c4d62;

  ${(props) =>
    props.height
      ? css`
          height: ${props.height};
        `
      : css`
          height: 100%;
        `}

  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}
  align-items: center;
  border: 2px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.35rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: ${(props) => props.theme.primary.light};
      color: ${(props) => props.theme.primary.light}; ;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${(props) => props.theme.primary.light}; ;
    `}

  textarea {
    outline: 0;
    background: transparent;
    flex: 1;
    border: 0;
    color: #3c4d62;
    margin-top: 8px;
    resize: none;
    background: transparent;
    width: 100%;
    height: 100%;
    border: 0;
    color: #3c4d62;

    &:disabled {
      color: #a3a3a3;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #a3a3a3;
    }
  }

  svg {
    font-size: 22px;
  }
  button {
    :hover {
      background-color: transparent;
      color: ${(props) => props.theme.primary.light};
      transition: 1s;
    }
  }
`;

export const Error = styled.p`
  color: #c53030;
  padding: 0.35rem 1rem;
  font-size: 0.9125rem;
  line-height: 1.25rem;
`;

export const Label = styled.label<ContainerProps>`
  font-size: 16px;
  width: 100%;

  ${(props) =>
    props.isErrored &&
    css`
      color: #c53030;
    `}

  ${(props) =>
    props.isFocused || props.isFilled
      ? css`
          visibility: visible;
          color: ${(props) => props.theme.primary.light}; ;
        `
      : css`
          visibility: hidden;
        `}
`;
