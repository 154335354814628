export const returningObjectWithoutNullProperties = (Object: any) => {
  const newObj: any = Object;

  for (const property in Object) {
    if (Object[property] === "" || Object[property] === null) {
      delete newObj[property];
    }
  }
  return newObj;
};

export const translateStatusValues = (status: string) => {
  switch (status) {
    case "paid":
      return "Pago";
    case "canceled":
      return "Cancelado";
    case "pending":
      return "Pendente";
    case "processing":
      return "Processando";
    case "rejected":
      return "Rejeitado";
    case "refused":
      return "Recusado";
    case "refunded":
      return "Ressarcido";
    case "expired":
      return "Expirado";
    case "opened":
      return "Em aberto";
    default:
      "//";
  }
};

//função para as tabelas de pix recebimentos e pagamentos
export const statusPropertyConverted = (property: string): any => {
  let prop: any = "";
  switch (property) {
    case "paid":
      prop = "paid";
      break;
    case "canceled":
      prop = "canceled";
      break;
    case "pending":
      prop = "pending";
      break;
    case "refused":
      prop = "refused";
      break;
    case "processing":
      prop = "pending";
      break;
    case "expired":
      prop = "refused";
      break;
    case "rejected":
      prop = "refused";
      break;
    case null:
      prop = "refused";
      break;
    case "refunded":
      prop = "paid";
      break;
    default:
      "refused";
  }
  return prop;
};
