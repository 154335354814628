import { useCallback, useEffect, useState } from "react";
import { normalizeAccountBalanceData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useAccountBalanceData = () => {
  const context = `accountBalance`;
  const [balanceValue, setBalanceValue] = useState(0);
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });
    
  const { data } = requestData;

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  const state = normalizeAccountBalanceData(requestData);

  useEffect(() => {
    if (!!data) {
      setBalanceValue(requestData.data.balance);
    }
  }, [data]);

  return {
    reset,
    loading: requestLoading,
    state,
    balanceValue,
    success: requestSuccess,
    handleGET,
  };
};
