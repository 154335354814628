import { format, sub } from "date-fns";
import React, { createContext, useContext, useState } from "react";
import { returningObjectWithoutNullProperties } from "../Utils/checkObjectProperties";

interface FilterContextData {
  getFilterObj: (tableName: string) => object;
  handleApplyFilters: (tableName: string, appliedFilter: object) => void;
  handleRemoveFilters: (tableName: string, appliedFilter: object) => void;
}

const FilterContext = createContext<FilterContextData>({} as FilterContextData);

const FilterProvider: React.FC = ({ children }) => {
  const [pixPaymentFilterObj, setPixPaymentFilterObj] = useState<any>({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    endToEndId: "",
    startAmount: "",
    endAmount: "",
    id: "",
    externalId: "",
    name: "",
    document: "",
    status: "",
  } as any);

  const [pixTotalsFilterObj, setPixTotalsFilterObj] = useState<any>({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  } as any);

  const [pixReceiveFilterObj, setPixReceiveFilterObj] = useState<any>({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
    endToEndId: "",
    startAmount: "",
    endAmount: "",
    id: "",
    externalId: "",
    name: "",
    document: "",
    status: "",
  } as any);
  const [receiptFilterObj, setReceiptFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  } as any);
  const [paymentsFilterObj, setPaymentsFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  } as any);
  const [extractFilterObj, setExtractFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
    // startAmount: "",
    // endAmount: "",
  } as any);
  const [refundsFilterObj, setRefundsFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  } as any);
  const [ticketsFilterObj, setTicketsFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  } as any);
  const [transfersFilterObj, setTransfersFilterObj] = useState<any>({
    start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
    end_date: format(new Date(), "yyyy-MM-dd"),
  } as any);

  const handleApplyFilters = (tableName: string, appliedFilter: object) => {
    const validatedObj = returningObjectWithoutNullProperties(
      appliedFilter
    ) as any;
    switch (tableName) {
      case "pixPayment":
        setPixPaymentFilterObj((oldstate) => ({
          ...oldstate,
          ...validatedObj,
        }));
        break;
      case "pixTotals":
        setPixTotalsFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "pixReceive":
        setPixReceiveFilterObj((oldstate) => ({
          ...oldstate,
          ...validatedObj,
        }));
        break;
      case "receipt":
        setReceiptFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "payments":
        setPaymentsFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "extract":
        setExtractFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "refunds":
        setRefundsFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "tickets":
        setTicketsFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
      case "transfers":
        setTransfersFilterObj((oldstate) => ({ ...oldstate, ...validatedObj }));
        break;
    }
  };
  const handleRemoveFilters = (tableName: string, appliedFilter: object) => {
    switch (tableName) {
      case "pixPayment":
        setPixPaymentFilterObj((oldstate) => ({
          ...oldstate,
          ...appliedFilter,
        }));
        break;
      case "pixTotals":
        setPixTotalsFilterObj((oldstate) => ({
          ...oldstate,
          ...appliedFilter,
        }));
        break;
      case "pixReceive":
        setPixReceiveFilterObj((oldstate) => ({
          ...oldstate,
          ...appliedFilter,
        }));
        break;
      case "receipt":
        setReceiptFilterObj((oldstate) => ({ ...oldstate, ...appliedFilter }));
        break;
      case "payments":
        setPaymentsFilterObj((oldstate) => ({ ...oldstate, ...appliedFilter }));
        break;
      case "extract":
        setExtractFilterObj((oldstate) => ({ ...oldstate, ...appliedFilter }));
        break;
      case "refunds":
        setRefundsFilterObj((oldstate) => ({ ...oldstate, ...appliedFilter }));
        break;
      case "tickets":
        setTicketsFilterObj((oldstate) => ({ ...oldstate, ...appliedFilter }));
        break;
      case "transfers":
        setTransfersFilterObj((oldstate) => ({
          ...oldstate,
          ...appliedFilter,
        }));
        break;
    }
  };

  function getFilterObj(tableName: string) {
    let filterObj = {};
    switch (tableName) {
      case "pixPayment":
        filterObj = pixPaymentFilterObj;
        break;
      case "pixReceive":
        filterObj = pixReceiveFilterObj;
        break;
      case "pixTotals":
        filterObj = pixTotalsFilterObj;
        break;
      case "receipt":
        filterObj = receiptFilterObj;
        break;
      case "payments":
        filterObj = paymentsFilterObj;
        break;
      case "extract":
        filterObj = extractFilterObj;
        break;
      case "refunds":
        filterObj = refundsFilterObj;
        break;
      case "tickets":
        filterObj = ticketsFilterObj;
        break;
      case "transfers":
        filterObj = transfersFilterObj;
        break;
    }

    return filterObj;
  }

  return (
    <FilterContext.Provider
      value={{
        getFilterObj,
        handleApplyFilters,
        handleRemoveFilters,
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

function useFilter() {
  const context = useContext(FilterContext);

  return context;
}

export { FilterProvider, useFilter };
