import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "90%",
    minHeight: "100%",
    margin: "0px auto",
  },

  backButton: {
    width: "fit-content",
    color: "#777",
    cursor: "pointer",

    "& svg": {
      marginRight: 5,
      verticalAlign: "middle",
      fontSize: 20,
    },

    "&:hover": {
      transition: "1s",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    width: "100%",
    justifyContent: "space-between",
  },

  paper: {
    width: "100%",
    marginBottom: 20,
  },
  box: {
    flexWrap: "wrap",
    marginBottom: 20,
    "& p": {
      marginBottom: 5,
    },
  },

  receiptInput: {
    color: "#777",
    cursor: "pointer",
    fontWeight: 500,
    width: "100%",
    borderRadius: 4,
    boxShadow: "0px 1px 8px 0px rgb(0 0 0 / 12%)",
    padding: 15,
    borderColor: "transparent",
    fontSize: 28,

    "&::placeholder": {
      fontSize: 20,
      color: "#cecece",
    },

    "&:focus": {
      border: "1px solid  ${(props) => props.theme.primary.light};",
    },
    subtitle: {},
    formSelect: {
      width: "100%",
    },
    "& select": {
      width: "100%",
    },
    menuItem: {
      display: "flex",
      width: "100%",
      flexDirection: "row",
      verticalAlign: "middle",
    },
  },
  receiptError: {
    color: "red",
    fontSize: 12,
    padding: 5,
  },
  receiptContainer: {
    width: "100%",
    maxWidth: 1110,
    minHeight: "100%",
    margin: "0px auto",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "center",
    padding: 15,
    width: "100%",
    justifyContent: "space-between",
  },
  backButton: {
    width: "fit-content",
    color: "#777",
    marginBottom: 20,
    cursor: "pointer",

    "& svg": {
      marginRight: 5,
      verticalAlign: "middle",
      fontSize: 20,
    },

    "&:hover": {
      transition: "1s",
    },
  },
  inputBox: {
    alignItems: "center",
    gap: 10,
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },

  greeting: {
    fontSize: "2.125rem",
    fontWeight: 400,
    lineHeight: 1.235,
    verticalAlign: "sub",
    textAlign: "center",
    letterSpacing: "0.00735em",
    paddingBottom: 20,
    margin: "20px 0px",
  },
}));
