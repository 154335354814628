import { FormGroup, FormControlLabel } from "@mui/material";
import styled from "styled-components";

export const ListContainer = styled.div`
  padding: 15px;
  min-height: 400px;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
`;

export const Content = styled.div`
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40;
  justify-content: center;
`;

export const FormGroupComponent = styled(FormGroup)`
  width: 100%;
  margin-top: 10px;
`;

export const FormControlLabelComponent = styled(FormControlLabel)`
  width: 100%;
  margin: 10px 0px;
`;
