import { Route, Switch, useHistory } from "react-router-dom";
import { CompanyLogo } from "../../Atoms";
import {
  Container,
  Content,
  AppBarShift,
  DrawerComponent,
  AppBarToolbar,
  DrawerHeader,
} from "./styles";
import { HeaderContent, MenuNavigation } from "../../Molecules";
import DesktopBalance from "../../Molecules/BalanceCard/DesktopBalance";

const DesktopSkeleton = ({
  routes,
  logout,
  accountBalanceData,
  accountData,
  refreshBalance,
  loadingRefreshBalance,
}) => {
  const history = useHistory();

  return (
    <Container>
      <AppBarShift position="fixed" elevation={0}>
        <AppBarToolbar>
          <HeaderContent logout={logout} accountData={accountData} />
        </AppBarToolbar>
      </AppBarShift>
      <DrawerComponent variant="persistent" anchor="left" open>
        <DrawerHeader onClick={() => history.push("/")}>
          <CompanyLogo height={50} width={200} />
        </DrawerHeader>
        <div style={{ height: "100%", width: "95%" }}>
          <DesktopBalance
            accountBalanceData={accountBalanceData}
            refreshBalance={refreshBalance}
            loadingRefreshBalance={loadingRefreshBalance}
          />
          <MenuNavigation />
        </div>
      </DrawerComponent>
      <Content>
        <DrawerHeader />
        <Switch>
          {routes?.map((route, index) => (
            <Route
              key={index.toString()}
              path={route?.path}
              component={route?.component}
              exact
            />
          ))}
        </Switch>
      </Content>
    </Container>
  );
};

export default DesktopSkeleton;
