import styled from "styled-components";

export const CustomInput = styled.input`
  background: transparent;
  color: #3c4d62;
  height: 3rem;
  align-items: center;
  border: 2px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.35rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
`;
