import styled from "styled-components";

export const Container = styled.div`
  width: 90%;
  margin: 0 auto;
`;

export const GreetingContainer = styled.div`
  padding-top: 40px;
`;
