export const getTransactionsByDay = (items: Array<TransactionType>) => {
  const arrayByDay = items.reduce((acc, curr) => {
    const day = curr.created_at.split("T")[0];
    if (!acc[day]) {
      acc[day] = [];
    }
    return acc;
  }, {});

  const days = Object.keys(arrayByDay);

  return days.map((key) => ({
    [key]: items.filter(({ created_at }) => created_at.includes(key)),
  }));
};

// Generated by https://quicktype.io

export interface TransactionType {
  category: Category;
  amount: number;
  balance_after: number;
  balance_before: number;
  created_at: string;
  name: string;
  description: string;
  id: string;
  operation_amount: number;
  operation_id: string;
}

export interface Category {
  icon: string;
  name: string;
}
