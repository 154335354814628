import { SnackbarProvider } from "notistack";
import React from "react";
import { snackbarValues } from "../Constants/Colors";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  success: {
    backgroundColor: "#E1FFEA",
    color: "black",
    "& svg": {
      color: "#52CC6C",
    },
  },
  error: {
    backgroundColor: "#FFECE9",
    color: "black",
    "& svg": {
      color: "#FF0000",
    },
  },
  warning: {
    backgroundColor: "#FAF1D7",
    color: "black",
    "& svg": {
      color: "#896804",
    },
  },
  info: {
    backgroundColor: "#CCEAFF",
    color: "black",
    "& svg": {
      color: "#0065B7",
    },
  },
});

const Notifications = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      maxSnack={5}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transitionDuration={{ exit: 380, enter: 400 }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};

export default Notifications;
