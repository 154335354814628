import {
  Checkbox,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import { CheckCircle, FileCopy, GetApp } from "@mui/icons-material";
import { Form } from "@unform/web";
import moment from "moment";
import "moment/locale/pt-br";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTheme } from "styled-components";
import * as Yup from "yup";
import { useCEPData, useTicketsData } from "../../../Hooks";
import { useToast } from "../../../Hooks/toast";
import { validateCPF_CNPJ } from "../../../Utils/CPF_CNPJValidation";
import { formatInputValue } from "../../../Utils/formatInputValue";
import { convertToCents, stringToDecimal } from "../../../Utils/formatters";
import getValidationErrors from "../../../Utils/getValidationErrors";
import {
  cepMask,
  cleanTaxIDMask,
  handleTaxIDInput,
} from "../../../Utils/masks";
import { Button, Input, TextArea } from "../../Atoms";
import { BackButton } from "../../Atoms/BackButton";
import { ModalLoader } from "../../Atoms/ModalLoader";
import { useSchema } from "./schema";
import {
  Content,
  FormControlLabelComponent,
  Header,
  ListContainer,
} from "./styles";

moment.locale("pt-br");

export const TicketStepper = ({ onClose, refreshList }) => {
  const { addToast } = useToast();
  const linkRef = useRef(null);
  const formRefReceipt = useRef(null);
  const [step, setStep] = useState(0);

  //SWITCHS
  const [hasAddress, setHasAddress] = useState(false);
  const [hasDiscount, setHasDiscount] = useState(false);
  const [hasPenalty, setHasPenalty] = useState(false);

  //CHECKBOX
  const [hasLimitDate, setHasLimitDate] = useState(false);
  const [hasPenaltyDate, setHasPenaltyDate] = useState(false);
  const [hasDiscountDate, setHasDiscountDate] = useState(false);

  //DISCOUNT & PENALTY
  const [discountType, setDiscountType] = useState("percent");
  const [discountValue, setDiscountValue] = useState(null);
  const [discountDate, setDiscountDate] = useState(null);
  const [penaltyValue, setPenaltyValue] = useState(null);
  const [penaltyDate, setPenaltyDate] = useState(null);
  const [penaltyFeeState, setPenaltyFee] = useState(null);

  //DEFAULT VALUE
  const [name, setName] = useState("");
  const [taxId, setTaxId] = useState("");
  const [transferValue, setTransferValue] = useState("");

  //ADDRESS
  const [number, setNumber] = useState(null);
  const [addressId, setAddressId] = useState(null);
  const [city, setCity] = useState(null);
  const [state, setState] = useState(null);
  const [address, setAddress] = useState(null);
  const [complemento, setComplemento] = useState(null);
  const [district, setDistrict] = useState(null);

  const theme = useTheme();

  //API CONST CALLS
  const {
    state: ticketsData,
    handlePOST: fetchTicketsPOST,
    loading: loadingTickets,
    success: successTickets,
  } = useTicketsData();
  const { state: pdf, handleGET, loading, success } = useTicketsData();
  const { requestData: cepData, fetchCEPData, resetCEP } = useCEPData();

  const handleDownloadTicket = (id) => {
    const afterID = "print";
    handleGET(null, id, afterID);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "boleto.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }
  useEffect(() => {
    const type = typeof pdf;

    if (type === "string") {
      downloadPDF(pdf);
    }
  }, [pdf]);

  useEffect(() => {
    if (addressId) {
      const parsedCEP = addressId.replace("-", "").replace("_", "");
      if (parsedCEP?.length === 8) {
        fetchCEPData({ cep: parsedCEP?.toString() });
      }
    }
  }, [addressId]);

  //LIMPA OS CAMPOS AO DESCELECIONAR SWITCH
  useEffect(() => {
    //LIMPA ENDEREÇO
    if (!hasAddress) {
      setNumber();
      setAddressId();
      setCity();
      setState();
      setDistrict();
      setAddress();
      setComplemento();
    }
    if (!hasPenalty) {
      setPenaltyFee();
      setPenaltyValue();
      setPenaltyDate();
      setHasPenaltyDate(false);
    }

    if (!hasDiscount) {
      setDiscountType();
      setDiscountValue();
      setHasDiscountDate(false);
      setDiscountDate();
    }
  }, [hasAddress, hasPenalty, hasDiscount]);

  useEffect(() => {
    setAddress(cepData?.logradouro);
    setCity(cepData?.localidade);
    setDistrict(cepData?.bairro);
    setState(cepData?.uf);

    if (cepData?.erro) {
      setAddress("");
      setCity("");
      setDistrict("");
      setState("");
    }
  }, [cepData]);

  const stepBack = useCallback(() => {
    setStep(0);
    onClose();
    setTaxId("");
    resetCEP();
    setNumber("");
    setAddressId("");
    setTransferValue("");
    setName("");
    if (successTickets.POST === true) {
      refreshList();
    }
  }, [successTickets]);

  const handleSubmitData = useCallback(
    async (data) => {
      try {
        // formRefReceipt.current.reset();
        let amount = convertToCents(data.value);
        let penaltyFee;
        let penaltyValue;
        let discountAmount;
        let address;
        let penalty;
        let discount;
        let max_payment_date;

        //CONVERTE VALORES PARA DECIMAL PARA VALIDACAO YUP E PARA APLICACAO DE CENTAVOS PARA O BACKEND

        if (!!data.penaltyValue) {
          penaltyValue = convertToCents(data.penaltyValue);
        }
        if (!!data.discountFixed && discountType === "fixed") {
          discountAmount = convertToCents(data.discountFixed);
        }

        if (!!data.discountPercent && discountType === "percent") {
          discountAmount = stringToDecimal(data.discountPercent);
        }

        data.value = stringToDecimal(data.value);
        data.penaltyValue = stringToDecimal(data.penaltyValue);
        data.penaltyFee = stringToDecimal(data.penaltyFee);

        data.discountFixed = stringToDecimal(data.discountFixed);
        data.discountPercent = stringToDecimal(data.discountPercent);

        //CREATE CONDITION TO SCHEMA
        const bollCPF_CNPJ = validateCPF_CNPJ(data.taxID);
        const boolDiscountFixed = data.value > data.discountFixed;
        data.discountPercent > 0 && data.discountPercent <= 100;
        const boolFeePercentage = data.penaltyFee > 0 && data.penaltyFee <= 20;
        const boolDiscountDate = moment(data.expireDate).isAfter(
          data.discountDate
        );

        let boolLimitDate;
        !!data.feeDate
          ? (boolLimitDate =
              moment(data.expireDate).isBefore(data.limitDate) ||
              moment(data.feeDate).isBefore(data.limitDate))
          : (boolLimitDate = moment(data.expireDate).isBefore(data.limitDate));

        const boolFeeDate = moment(data.expireDate).isBefore(data.penaltyDate);

        //GET CHECKBOX VALUES AND MOUNT PAYLOAD
        if (hasAddress) {
          data = {
            ...data,
            hasAddress: hasAddress,
          };
          address = {
            postal_code: addressId,
            number: data.number,
            complement: data.complemento,
            address: !!cepData?.logradouro ? cepData?.logradouro : data.address,
            state: !!cepData?.uf ? cepData?.uf : data.state,
            district: !!cepData?.bairro ? cepData?.bairro : data.district,
            city: !!cepData?.localidade ? cepData?.localidade : data.city,
          };
        }

        if (hasDiscount) {
          discount = {
            type: discountType,
            value: discountAmount,
            max_discount_date: !!data.discountDate
              ? `${data.discountDate}T23:59:59-03:00`
              : null,
          };

          if (discountType === "fixed") {
            data = {
              ...data,
              isDiscountFixed: true,
            };
          }

          if (discountType === "percent") {
            data = {
              ...data,
              isDiscountPercent: true,
            };
          }
        }
        if (hasPenalty) {
          data = {
            ...data,
            hasPenalty: hasPenalty,
          };
          penalty = {
            amount: penaltyValue,
            fee: +data.penaltyFee,
            penalty_date: !!data.penaltyDate
              ? `${data.penaltyDate}T23:59:59-03:00`
              : null,
          };
        }

        if (hasDiscountDate) {
          data = {
            ...data,
            hasDiscountDate: hasDiscountDate,
          };
        }

        if (hasPenaltyDate) {
          data = {
            ...data,
            hasPenaltyDate: hasPenaltyDate,
          };
        }

        if (hasLimitDate) {
          data = {
            ...data,
            hasLimitDate: hasLimitDate,
          };
          max_payment_date = data.limitDate
            ? `${data.limitDate}T23:59:59-03:00`
            : null;
        }

        //CRIA O OBJETO DE VALIDACAO
        let validateSchema = {
          bollCPF_CNPJ,
          boolFeePercentage,
          boolDiscountFixed,
          boolDiscountDate,
          boolFeeDate,
          boolLimitDate,
        };

        let schema = useSchema(validateSchema);

        await schema.validate(data, {
          abortEarly: false,
        });

        const payload = {
          expiration: `${data.expireDate}T23:59:59-03:00`,
          amount,
          description: data?.description,
          payer: {
            document_type: data.taxID.length > 14 ? "CNPJ" : "CPF",
            document: cleanTaxIDMask(data.taxID),
            name: data.name,
            address,
          },
          max_payment_date,
          discount,
          penalty,
        };
        // console.log(payload);
        fetchTicketsPOST(payload);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefReceipt.current?.setErrors(errors);
          return;
        }
      }
    },
    [
      cepData,
      hasAddress,
      hasDiscount,
      hasDiscountDate,
      discountType,
      hasPenalty,
      hasLimitDate,
      hasPenaltyDate,
    ]
  );

  const copyToClipboard = useCallback(() => {
    try {
      if (linkRef.current) linkRef.current.select();
      document.execCommand("copy");
      addToast({
        title: "Linha copiada!",
        type: "info",
      });
    } catch (err) {
      console.log(err);
    }
  }, [addToast]);

  useEffect(() => {
    successTickets.POST && !loadingTickets.POST && setStep(1);
  }, [successTickets, successTickets]);

  const stepContactData = (
    <div>
      <ModalLoader
        loading={loadingTickets.POST}
        text={"Carregando informações"}
      />
      <Header>
        <Typography variant="h5">Emitir boleto</Typography>
        <BackButton handleClose={() => stepBack()} />
      </Header>
      <Content>
        <Form ref={formRefReceipt} onSubmit={handleSubmitData}>
          {/* MAINLY */}
          <Input
            type="date"
            name="expireDate"
            placeholder="Vencimento"
            defaultValue={moment().add("days", 1).format("YYYY-MM-DD")}
          />
          <Input
            value={transferValue}
            onChange={(e) => {
              setTransferValue(formatInputValue(e.currentTarget.value));
            }}
            name="value"
            type="text"
            maxLength={12}
            placeholder="Valor (R$)"
          />
          <Input
            value={taxId}
            type="text"
            name="taxID"
            placeholder="CPF ou CNPJ"
            onChange={(e) => {
              setTaxId(handleTaxIDInput(e.currentTarget.value));
            }}
          />
          <Input
            name="name"
            placeholder="Nome"
            value={name}
            onChange={(e) => setName(e.currentTarget.value)}
          />
          <TextArea
            maxLength={80}
            height={"5rem"}
            name="description"
            placeholder="Descrição do boleto (Opcional)"
            type="text"
          />
          <FormControlLabelComponent
            control={
              <Checkbox
                color="primary"
                value={hasLimitDate}
                onChange={() => setHasLimitDate(!hasLimitDate)}
              />
            }
            label="Configurar data limite de pagamento do boleto"
          />
          {hasLimitDate && (
            <Input
              type="date"
              name="limitDate"
              defaultValue={moment().add("days", 1).format("YYYY-MM-DD")}
              placeholder="Data limite para pagamento do boleto"
            />
          )}

          <FormControlLabelComponent
            control={
              <Switch
                name="hasAddress"
                color="primary"
                value={hasAddress}
                onChange={() => {
                  setHasAddress(!hasAddress);
                }}
              />
            }
            label="Configurar endereço"
          />
          {/* ADDRESS */}
          {hasAddress && (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: 20,
                }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    name="cep"
                    style={{ marginRight: 10 }}
                    placeholder="CEP"
                    maxLength={9}
                    onChange={(e) =>
                      setAddressId(cepMask(e.currentTarget.value))
                    }
                    value={addressId}
                  />
                </div>
                <div>
                  <Input
                    name="number"
                    value={number}
                    maxLength={12}
                    onChange={(e) =>
                      setNumber(e.currentTarget.value.replace(/\D/g, ""))
                    }
                    width={20}
                    placeholder="Nº"
                  />
                </div>
              </div>
              <Input
                name="address"
                placeholder="Endereço"
                value={address}
                onChange={(e) =>
                  setAddress(e.currentTarget.value.replace(/[0-9]+/g, ""))
                }
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  gap: 20,
                }}
              >
                <div style={{ width: "100%" }}>
                  <Input
                    name="city"
                    placeholder="Cidade"
                    value={city}
                    onChange={(e) =>
                      setCity(e.currentTarget.value.replace(/[0-9]+/g, ""))
                    }
                  />
                </div>
                <div>
                  <Input
                    name="state"
                    placeholder="Estado"
                    value={state}
                    maxLength={2}
                    onChange={(e) =>
                      setState(
                        e.currentTarget.value
                          .toLocaleUpperCase()
                          .replace(/[0-9]+/g, "")
                      )
                    }
                  />
                </div>
              </div>
              <Input
                name="district"
                placeholder="Bairro"
                value={district}
                onChange={(e) =>
                  setDistrict(e.currentTarget.value.replace(/[0-9]+/g, ""))
                }
              />
              <Input
                name="complemento"
                placeholder="Complemento"
                value={complemento}
                onChange={(e) => setComplemento(e.currentTarget.value)}
              />
            </>
          )}
          {/* FEE */}
          <FormControlLabelComponent
            control={
              <Switch
                color="primary"
                value={hasPenalty}
                onChange={() => {
                  setHasPenalty(!hasPenalty);
                }}
              />
            }
            label="Configurar multa"
          />
          {hasPenalty && (
            <>
              <FormControlLabelComponent
                control={
                  <Checkbox
                    color="primary"
                    value={hasPenaltyDate}
                    onChange={() => setHasPenaltyDate(!hasPenaltyDate)}
                  />
                }
                label="Configurar valor por %"
              />
              {!hasPenaltyDate && (
                <>
                  <Input
                    value={penaltyValue}
                    onChange={(e) => {
                      setPenaltyValue(formatInputValue(e.currentTarget.value));
                    }}
                    name="penaltyValue"
                    type="text"
                    placeholder="Valor da multa"
                  />
                </>
              )}
              {hasPenaltyDate && (
                <>
                  <Input
                    value={penaltyFeeState}
                    onChange={(e) => {
                      // console.log(formatInputValue(e.currentTarget.value));
                      setPenaltyFee(formatInputValue(e.currentTarget.value));
                    }}
                    name="penaltyFee"
                    type="text"
                    maxLength={5}
                    placeholder="% de juros"
                  />
                </>
              )}
              <Input
                type="date"
                name="penaltyDate"
                value={penaltyDate}
                defaultValue={moment().add("days", 2).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setPenaltyDate(e.currentTarget.value);
                }}
                placeholder="Data de aplicação da multa"
              />
            </>
          )}
          {/* DISCOUNT */}
          <FormControlLabelComponent
            control={
              <Switch
                color="primary"
                value={hasDiscount}
                onChange={() => {
                  setHasDiscount(!hasDiscount);
                }}
              />
            }
            label="Configurar desconto"
          />

          {hasDiscount && (
            <>
              <FormLabel component="p" style={{ marginTop: 10 }}>
                Tipo de desconto
              </FormLabel>
              <RadioGroup value={discountType} defaultValue={"percent"}>
                <FormControlLabelComponent
                  control={
                    <Radio
                      color="primary"
                      value={"percent"}
                      onChange={() => setDiscountType("percent")}
                    />
                  }
                  label="Valor percentual"
                />
                <FormControlLabelComponent
                  control={
                    <Radio
                      color="primary"
                      value={"fixed"}
                      onChange={() => setDiscountType("fixed")}
                    />
                  }
                  label="Valor fixo"
                />
              </RadioGroup>
              {discountType === "percent" ? (
                <Input
                  value={discountValue}
                  onChange={(e) => {
                    setDiscountValue(formatInputValue(e.currentTarget.value));
                  }}
                  maxLength={5}
                  name="discountPercent"
                  type="text"
                  placeholder={"% de desconto"}
                />
              ) : (
                <Input
                  value={discountValue}
                  onChange={(e) => {
                    setDiscountValue(formatInputValue(e.currentTarget.value));
                  }}
                  name="discountFixed"
                  type="text"
                  placeholder={"Desconto(R$)"}
                />
              )}

              <FormControlLabelComponent
                control={
                  <Checkbox
                    color="primary"
                    value={hasDiscountDate}
                    onChange={() => setHasDiscountDate(!hasDiscountDate)}
                  />
                }
                label="Configurar data limite para aplicação de desconto"
              />
              {hasDiscountDate && (
                <Input
                  type="date"
                  value={discountDate}
                  onChange={(e) => setDiscountDate(e.currentTarget.value)}
                  name="discountDate"
                  placeholder="Data limite para aplicação do desconto"
                />
              )}
            </>
          )}

          <Button
            disabled={loadingTickets.POST}
            type="submit"
            style={{ width: 200, margin: 10 }}
          >
            Emitir boleto
          </Button>
        </Form>
      </Content>
    </div>
  );

  const stepFinalSuccess = (
    <div>
      <Header>
        <Typography variant="h5">Boletos</Typography>
        <BackButton handleClose={() => stepBack()} />
      </Header>
      <div
        style={{
          padding: 20,
          height: "auto",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            padding: 20,
            margin: "0px auto",
            width: "50%",
            display: "flex",
            gap: 20,
            flexDirection: "column",
          }}
        >
          <Typography variant="h5">
            Boleto emitido com sucesso!
            <CheckCircle
              style={{
                verticalAlign: "sub",
                color: "green",
                marginLeft: 10,
                fontSize: 30,
              }}
            />
          </Typography>
          <p>
            Para abastecer sua conta você deve pagar o boleto com o código
            digitável ou se preferir baixe o pdf de seu boleto.
          </p>
          <span style={{ color: "#999" }}>
            Vence em: {moment(ticketsData?.expiration).format("DD/MM/YYYY")}
          </span>
          <input
            style={{
              fontSize: 14,
              width: "100%",
              margin: "0 auto",
              fontWeight: 700,
              color: theme.primary.original,
              border: 0,
            }}
            ref={linkRef}
            value={ticketsData?.barcode_line}
            id="linkArea"
          />

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: 20,
            }}
          >
            <Button
              style={{ width: 250 }}
              // onClick={() => {
              //   {
              //     // window.open(ticketsData?.link);
              //     window.open(
              //       `https://api.valut.com.br/carteira/boleto/${ticketsData?.id}/print`
              //     );
              //   }
              // }}
              onClick={() => handleDownloadTicket(ticketsData?.id)}
            >
              Baixar boleto
              <GetApp style={{ verticalAlign: "sub", marginLeft: 10 }} />
            </Button>

            <Button
              style={{ width: 250 }}
              onClick={() => {
                copyToClipboard();
              }}
            >
              Copiar linha digitável
              <FileCopy style={{ verticalAlign: "sub", marginLeft: 10 }} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <Paper elevation={3}>
      <ListContainer>
        {step === 0 && stepContactData}
        {step === 1 && successTickets.POST && stepFinalSuccess}
      </ListContainer>
    </Paper>
  );
};
