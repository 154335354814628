export const pixOptions = [
  {
    key: "email",
    title: "E-mail",
  },
  {
    key: "document",
    title: "CPF/CNPJ",
  },
  {
    key: "phone",
    title: "Celular",
  },
  {
    key: "randomKey",
    title: "Chave aleatória",
  },
];
