import { Card, IconButton } from "@mui/material";
import styled, { css } from "styled-components";

interface ExpandMoreButtonProps {
  expand: boolean;
}
interface FavoriteButtonProps {
  favorite: boolean;
}

export const CardContainer = styled(Card)`
  width: 80%;
`;

export const ExpandMoreButton = styled(IconButton)<ExpandMoreButtonProps>`
  ${(props) =>
    props.expand
      ? css`
          transform: rotate(180deg);
        `
      : css`
          transform: rotate(0deg);
        `}
`;
export const FavoriteButton = styled(IconButton)<FavoriteButtonProps>`
  && {
    color: ${(props) => (props.favorite ? "#0096ff" : "inherit")};
    border-color: ${(props) => (props.favorite ? "#0096FF" : "inherit")};
  }
`;
