import { ArrowBack } from "@mui/icons-material";
import React, { useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { CompanyLogo, Button } from "../../../Components/Atoms";
import { Container, Content, VerificationContainer } from "./styles";
import { AuthContext } from "../../../Contexts/Auth";
// import MetaDecorator from "../../components/MetaDecorator";

const ForgotPassword: React.FC = () => {
  const history = useHistory();
  const [stateAuthContext, dispatchAuthContext] = useContext(AuthContext);
  const { firstStepData } = stateAuthContext;

  return (
    <Container>
      <Content>
        <VerificationContainer>
          <CompanyLogo width={200} height={60} />
          <p>Esqueceu sua senha?</p>
          <p>Para sua segurança, redefina sua senha pelo nosso aplicativo.</p>
          <Button
            action={"return"}
            onClick={() => {
              history.push("/");
              dispatchAuthContext({
                type: "clear",
              });
            }}
          >
            Voltar
          </Button>
        </VerificationContainer>
      </Content>
    </Container>
  );
};

export default ForgotPassword;
