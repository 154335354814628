import PropTypes from "prop-types";
import React, { useReducer } from "react";
import { loadItem, removeItem, saveItem } from "../Utils";

const token = loadItem("token");

const initialState = {
  firstStepData: {},
  secondStepToken: null,
  token: token || null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setFirstStepData":
      return {
        ...state,
        firstStepData: {
          ...action.firstStepData,
        },
        secondStepToken: action.token,
      };
    case "setApplicationToken":
      saveItem(action.token, "token");
      return {
        ...state,
        firstStepData: {},
        secondStepToken: null,
        token: action.token,
      };
    case "clear":
      removeItem("token");
      return { token: null, firstStepData: {}, secondStepToken: null };
    default:
      throw new Error("Unexpected action");
  }
};

export const AuthContext = React.createContext();

const Provider = ({ children }) => {
  const context = useReducer(reducer, initialState);
  return (
    <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
  );
};

Provider.defaultProps = {
  children: <></>,
};

Provider.propTypes = {
  children: PropTypes.node,
};

export default Provider;
