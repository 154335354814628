import {
  Avatar,
  Dialog,
  DialogActions,
  DialogTitle,
  Hidden,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Delete, GetApp } from "@mui/icons-material/";
import { useEffect, useState } from "react";
import { darken } from "polished";
import { Button } from "../../../Components/Atoms";
import BackImage from "../../../Assets/no-card.png";
import { Loading } from "../../Atoms";
import { useTicketsData } from "../../../Hooks";
import { useCallback } from "react";
import moment from "moment";
import { formatCurrency } from "../../../Utils/formatters";
import { ModalLoader } from "../../Atoms/ModalLoader";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  noTickets: {
    width: "100%",
    minHeight: "90%",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
  },
  deleteButton: {
    color: "#DD453D",
    "&:hover": {
      color: `${darken(0.1, "#DD453D")} !important`,
    },
  },
});

export const TicketsList = ({ itens, loading, refreshList }) => {
  const classes = useStyles();
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [ticketSelected, setTicketSelected] = useState(null);
  const {
    handleDELETE: fetchTicketsDELETE,
    loading: loadingDeletion,
    success: successTickets,
  } = useTicketsData();
  const { handleGET, state, loading: loadingPDF } = useTicketsData();

  const handleDeleteTicket = (e) => {
    setTicketSelected(e);
    setDeleteConfirm(true);
  };

  const handleConfirm = useCallback(() => {
    fetchTicketsDELETE(ticketSelected);
  }, [ticketSelected]);

  const handleDownloadTicket = (id) => {
    const afterID = "print";
    // console.log(id);
    handleGET(null, id, afterID);
  };

  function downloadPDF(pdf) {
    const linkSource = `data:application/pdf;base64,${pdf}`;
    const downloadLink = document.createElement("a");
    const fileName = "boleto.pdf";
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  }

  useEffect(() => {
    if (successTickets.DELETE && !loadingDeletion.DELETE) {
      setDeleteConfirm(false);
      refreshList();
    }
  }, [successTickets, loadingDeletion]);

  useEffect(() => {
    const type = typeof state;
    if (type === "string") {
      downloadPDF(state);
    }
  }, [state]);

  if (loading) {
    return <ModalLoader open={loading} text={"Carregando informações"} />;
  }
  return (
    <>
      {!!itens && itens.length > 0 ? (
        <Hidden mdDown>
          <Table
            className={classes.table}
            size="small"
            aria-label="a dense table"
          >
            <TableHead>
              <TableRow>
                <TableCell align="center">Cliente</TableCell>
                <TableCell align="center">Emissão</TableCell>
                <TableCell align="center">Vencimento</TableCell>
                <TableCell align="center">Valor</TableCell>
                <TableCell align="center">Pagador</TableCell>
                <TableCell align="center">Documento do pagador</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Download</TableCell>
                {/* <TableCell align="center">Cancelar</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {itens?.map((row) => (
                <TableRow key={row?.id}>
                  <TableCell align="center">{row?.account_name}</TableCell>
                  <TableCell align="center">
                    {moment(row?.created_at).format("DD/MM/YYYY [às] HH:mm")}
                  </TableCell>
                  <TableCell align="center">
                    {moment(row?.expiration).format("DD/MM/YYYY")}
                  </TableCell>
                  <TableCell align="center">
                    {formatCurrency(row?.amount)}
                  </TableCell>
                  <TableCell align="center">
                    {!!row?.payer?.name ? row?.payer?.name : "-"}
                  </TableCell>
                  <TableCell align="center">
                    {!!row?.payer?.document ? row?.payer?.document : "-"}
                  </TableCell>
                  <TableCell align="center">{row?.status}</TableCell>
                  <TableCell align="center">
                    {/* {loadingPDF.GET ? (
                      <Loading />
                    ) : ( */}
                    <IconButton
                      size="small"
                      disabled={
                        row.status.toLowerCase() === "cancelado" ? true : false
                      }
                      // onClick={() => window.open(row?.url)}
                      onClick={() => handleDownloadTicket(row?.id)}
                    >
                      <GetApp fontSize="small" />
                    </IconButton>
                    {/* )} */}
                  </TableCell>
                  {/* <TableCell align="center">
                    <IconButton
                      className={classes.deleteButton}
                      size="small"
                      disabled={
                        row.status.toLowerCase() === "cancelado" ? true : false
                      }
                      onClick={() => handleDeleteTicket(row?.id)}
                    >
                      <Delete fontSize="small" />
                    </IconButton>
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Hidden>
      ) : (
        <div className={classes.noTickets}>
          <img src={BackImage} width="auto" />
          <Typography variant="h4">
            Você ainda não possui boletos registrados
          </Typography>
          <br />
          <Typography variant="h6">
            Para registrar um boleto clique no botão acima: "novo boleto"
          </Typography>
        </div>
      )}
      <Dialog open={deleteConfirm} onClose={() => setDeleteConfirm(false)}>
        <DialogTitle id="alert-dialog-title">
          Confirmar cancelamento do boleto?
        </DialogTitle>
        <DialogActions>
          <Button
            onClick={() => setDeleteConfirm(false)}
            disabled={loadingDeletion.DELETE}
            action={"cancel"}
          >
            Cancelar
          </Button>
          <Button onClick={handleConfirm} loading={loadingDeletion.DELETE}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

TicketsList.propTypes = {};
