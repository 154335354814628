import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  right: 27.5%;
  bottom: 0;
  padding: 30px;
  overflow: hidden;
  z-index: 100000;
  bottom: 0;
`;
