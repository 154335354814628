import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { menuRoutes } from "../../../Constants/RoutesByType";
import { Container, MenuItem, Menu, Subtitle } from "./styles";
import { useState } from "react";

interface IMenuRoutes {
  path: string;
  label: string;
  icon?: any;
  isHidden?: boolean;
}

export const MenuNavigation = () => {
  let location = useLocation();
  const menuRouteOptions: IMenuRoutes[] = menuRoutes;

  return (
    <Container container spacing={2}>
      <Grid item xs={12}>
        <Subtitle gutterBottom variant="subtitle2">
          MENU
        </Subtitle>
        <Menu>
          {menuRouteOptions?.map((el, index) => {
            if (el?.isHidden) {
              return;
            }
            const Icon = el?.icon;
            return (
              <Link key={index} to={el?.path}>
                <MenuItem
                  style={{ padding: 10 }}
                  key={index}
                  isSelected={location.pathname === el?.path}
                >
                  <ListItemIcon>
                    <Icon />
                  </ListItemIcon>
                  <ListItemText primary={el.label} />
                </MenuItem>
              </Link>
            );
          })}
        </Menu>
      </Grid>
    </Container>
  );
};
