import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  width: 90%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const PixContainer = styled.div`
  margin: 40px 0px;
  justify-content: space-between;
  display: flex;
  width: 100%;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

export const PixActions = styled.section`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 35%;
`;
export const CardsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  gap: 10px;
`;

export const LinkContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const PixTransactions = styled.section`
  width: 60%;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  background-color: white;
  padding: 10px;
`;
