import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Button } from "../Button";

export const ConfirmDialogModal = ({
  text,
  open,
  onClose,
  refreshAction,
  description,
}) => {
  const [isOpen, setIsOpen] = useState(open);
  const theme = useTheme();

  useEffect(() => {
    setIsOpen(open);
  }, [open, onClose]);

  const handleAction = useCallback((action) => {
    refreshAction(action);
  }, []);

  return (
    <div>
      <Dialog open={isOpen} keepMounted onClose={onClose}>
        <DialogTitle>{text}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {description}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button action={"cancel"} onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={() => refreshAction(true)}>Confirmar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
