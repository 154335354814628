import { useRef, useState, useCallback } from "react";
import {
  Typography,
  TextField,
  Stepper,
  Step,
  StepLabel,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { useStyles } from "./styles";
import * as Yup from "yup";
import { Form } from "@unform/web";
import { Button, Input } from "../../Atoms";
import { formatInputValue } from "../../../Utils/formatInputValue";
import { validateCPF_CNPJ } from "../../../Utils/CPF_CNPJValidation";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { useTransferTransaction } from "../../../Hooks/TransferTransaction";
import { cleanTaxIDMask, handleTaxIDInput } from "../../../Utils/masks";
import { useBanksList } from "../../../Hooks/banksList";
import { useEffect } from "react";
import { Autocomplete } from "@mui/lab";
import { PasswordConfirm } from "../PasswordConfirm";
import { BalanceWarning } from "../../Atoms/BalanceWarning";
import {
  formatCurrency,
  convertToCents,
  stringToDecimal,
} from "../../../Utils/formatters";
import { useAccountBalanceData } from "../../../Hooks";
import { ModalLoader } from "../../Atoms/ModalLoader";
import { useToast } from "../../../Hooks/toast";
import { useHistory } from "react-router-dom";

//Outros bancos

export const OtherBanksStep = ({ refreshSteps }) => {
  const { addToast } = useToast();
  const history = useHistory();
  const formRefStep0 = useRef(null);
  const formRefStep1 = useRef(null);
  const formRefStep2 = useRef(null);
  const formRefStep3 = useRef(null);
  const classes = useStyles();
  const [step, setStep] = useState(0);
  const steps = getSteps();
  const [taxId, setTaxId] = useState("");
  const [bankSelected, setBankSelected] = useState("");
  const [requirePassword, setRequirePassword] = useState(false);
  const [agencyData, setAgencyData] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountData, setAccountData] = useState("");
  const [transferDescription, setTransferDescription] = useState("");
  const [transferValue, setTransferValue] = useState("");
  const [transferValueConverted, setTransferValueConverted] = useState(0);
  const [name, setName] = useState("");

  const {
    loading: transactionLoading,
    handlePOST: fetchTransaction,
    state: transactionState,
    success: transactionStatus,
  } = useTransferTransaction();
  const {
    state: banksList,
    handleGET: fetchBanksList,
    loading: bankListLoading,
  } = useBanksList();

  //VERIFYBALANCE
  const { balanceValue, handleGET: fetchAccountBalanceGET } =
    useAccountBalanceData();

  useEffect(() => {
    fetchBanksList();
    fetchAccountBalanceGET();
  }, []);

  function getSteps() {
    return [
      "Dados bancários",
      "Dados do recebedor",
      "Detalhes da transação",
      "Confirme os dados e finalize",
    ];
  }

  const accountStep = useCallback(
    async (data) => {
      try {
        if (!bankSelected) {
          alert("Escolha a sua instituição bancária");
          return;
        }

        if (!accountType) {
          alert("Escolha o tipo de conta");
          return;
        }
        const schema = Yup.object().shape({
          account: Yup.string().required("Conta obrigatória."),
          branch: Yup.string().required("Agência obrigatória"),
        });
        await schema.validate(data, {
          abortEarly: false,
        });
        setStep(1);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRefStep0.current?.setErrors(errors);
          return;
        }
      }
    },
    [bankSelected, accountType]
  );

  const personStep = useCallback(async (data) => {
    try {
      const bollCPF_CNPJ = validateCPF_CNPJ(data.document);
      const schema = Yup.object().shape({
        document: Yup.string()
          .required("CPF/CNPJ obrigatório.")
          .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
        name: Yup.string().required("Nome obrigatório"),
      });

      // !!isTransactionSuccess.POST && setStep(2);
      await schema.validate(data, {
        abortEarly: false,
      });
      setStep(2);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRefStep1.current?.setErrors(errors);

        return;
      }
    }
  }, []);

  const transactionValueStep = useCallback(async (data) => {
    try {
      let convertedValue = convertToCents(data.value);
      data.value = stringToDecimal(data.value);
      const schema = Yup.object().shape({
        value: Yup.number()
          .typeError("Digite o valor do link")
          .required("Digite o valor do link")
          .moreThan(0.99, "Valor deve ser maior que R$1.00"),
      });
      await schema.validate(data, {
        abortEarly: false,
      });

      setTransferValueConverted(convertedValue);
      setStep(3);
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        const errors = getValidationErrors(err);
        formRefStep2.current?.setErrors(errors);
        return;
      }
    }
  }, []);

  const handleFinishTransaction = useCallback(
    (pass) => {
      if (pass.length !== 4) return;
      let taxIDData = cleanTaxIDMask(taxId);
      fetchTransaction({
        amount: transferValueConverted,
        description: transferDescription,
        target: {
          account_code: accountData.substring(0, accountData.lastIndexOf("-")),
          account_digit: accountData.substring(
            accountData.lastIndexOf("-") + 1
          ),
          branch_code: agencyData,
          institution_code: bankSelected.id,
          account_type: accountType,
          type: "external",
          entity: {
            id: "32420E30-8AF8-42EF-920B-621FB7D6C382",
            name: name,
            document: taxIDData,
            document_type: taxIDData.length >= 14 ? "cnpj" : "cpf",
          },
          validation: {
            password_combination: pass,
          },
        },
      });
      setRequirePassword(false);
    },
    [
      transferDescription,
      transferValueConverted,
      accountData,
      bankSelected,
      name,
      agencyData,
      taxId,
      accountType,
    ]
  );

  //REDIRECIONA PARA TELA FINAL
  useEffect(() => {
    !!transactionState &&
      !transactionLoading.POST &&
      refreshSteps({
        step: 2,
        status: transactionStatus.POST,
        state: transactionState,
      });
  }, [transactionLoading, transactionState]);

  //Workaround para redirecionar para a tela inicial qnd der algum erro na transferencia
  /* TODO: Implementar a requisição da api de maneira individual, para que o erro seja tratado
    em um block try/catch ao invés de utilizar um side-effect para tratativa de erro.
    Semelhante a maneira como esta fazendo transferencias via PIX
*/
  useEffect(() => {
    if (transactionLoading.POST === false && transactionStatus.POST === false) {
      history.push("/");
    }
  }, [transactionLoading, transactionStatus]);

  const step0 = (
    <div>
      <div className={classes.header}>
        <Typography variant="h5">
          Transferência para outras instituições bancárias
        </Typography>
      </div>

      <Form ref={formRefStep0} onSubmit={accountStep} className={classes.form}>
        {banksList && (
          <Autocomplete
            onChange={(event, newValue) => {
              setBankSelected(newValue);
            }}
            options={banksList.filter(
              (opt) => opt.id !== "0" && opt.id !== "000"
            )}
            getOptionLabel={(option) => `${option.id} - ${option.short_name}`}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField {...params} label="Banco" variant="outlined" />
            )}
          />
        )}
        <FormControl
          variant="outlined"
          style={{ marginBottom: 20, marginTop: 40, width: "100%" }}
        >
          <InputLabel
            style={{
              width: "auto",
              background: "white",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            Tipo de conta
          </InputLabel>
          <Select
            style={{ width: "100%" }}
            value={accountType}
            onChange={(e) => setAccountType(e.target.value)}
          >
            <MenuItem value={"corrente"}>Corrente</MenuItem>
            <MenuItem value={"poupanca"}>Poupança</MenuItem>
            <MenuItem value={"pagamento"}>Pagamento</MenuItem>
          </Select>
        </FormControl>

        <Input
          name="branch"
          placeholder="Agência"
          maxlength="5"
          onChange={(e) => {
            setAgencyData(e.currentTarget.value.replace(/[^\d]/g, ""));
          }}
          value={agencyData}
        />
        <Input
          name="account"
          placeholder="Conta"
          maxlength="18"
          onKeyUp={(e) => {
            e.currentTarget.value.length > 3 &&
              !e.currentTarget.value.includes("-") &&
              setAccountData(
                e.currentTarget.value.substr(
                  0,
                  e.currentTarget.value.length - 1
                ) +
                  "-" +
                  e.currentTarget.value.substr(e.currentTarget.value.length - 1)
              );
          }}
          onChange={(e) => {
            setAccountData(e.currentTarget.value.replace(/[^\d]/g, ""));
          }}
          value={accountData}
        />
        <Button
          action={"return"}
          style={{
            width: 200,
            margin: 10,
          }}
          onClick={() => refreshSteps({ step: 0 })}
        >
          Voltar
        </Button>
        <Button style={{ width: 200, margin: 10 }} type="submit">
          Continuar
        </Button>
      </Form>
    </div>
  );

  const step1 = (
    <div>
      <div className={classes.header}>
        <Typography variant="h5">
          Transferência para outra instituição bancária
        </Typography>
      </div>

      <Form ref={formRefStep1} onSubmit={personStep} className={classes.form}>
        <Input
          value={taxId}
          type="text"
          name="document"
          placeholder="CPF ou CNPJ"
          onChange={(e) => {
            setTaxId(handleTaxIDInput(e.currentTarget.value));
          }}
        />
        <Input
          name="name"
          placeholder="Nome"
          type="text"
          value={name}
          onChange={(e) =>
            setName(e.currentTarget.value.replace(/[0-9]+/g, ""))
          }
        />
        <Button
          style={{ width: 200, margin: 10 }}
          action={"return"}
          onClick={() => setStep(step - 1)}
        >
          Voltar
        </Button>
        <Button type="submit" style={{ width: 200, margin: 10 }}>
          Continuar
        </Button>
      </Form>
    </div>
  );
  const step2 = (
    <div>
      <div className={classes.header}>
        <Typography variant="h5">
          Transferência para outra instituição bancária
        </Typography>
      </div>
      <Form
        ref={formRefStep2}
        onSubmit={transactionValueStep}
        className={classes.form}
      >
        <Input
          value={transferValue}
          onChange={(e) => {
            setTransferValue(formatInputValue(e.currentTarget.value));
          }}
          name="value"
          type="text"
          maxLength={12}
          placeholder="Valor (R$)"
        />

        <Input
          name="description"
          type="text"
          placeholder="Descrição"
          value={transferDescription}
          onChange={(e) => setTransferDescription(e.currentTarget.value)}
        />
        <Button
          action={"return"}
          style={{ width: 200, margin: 10 }}
          onClick={() => setStep(step - 1)}
        >
          Voltar
        </Button>
        <Button type="submit" style={{ width: 200, margin: 10 }}>
          Continuar
        </Button>
      </Form>
    </div>
  );

  const step3 = (
    <div>
      <div className={classes.header}>
        <Typography variant="h5">
          Confirme os dados antes de concluir a transferência
        </Typography>
      </div>
      <Form
        ref={formRefStep3}
        onSubmit={() => setRequirePassword(true)}
        className={classes.form}
      >
        <Input
          disabled={true}
          value={taxId}
          name="document"
          type="text"
          placeholder={taxId.length > 14 ? "CNPJ" : "CPF"}
        />
        <Input
          disabled={true}
          value={name}
          name="name"
          type="text"
          placeholder="Nome"
        />
        <Input
          disabled={true}
          value={`${bankSelected?.id} - ${bankSelected?.short_name}`}
          name="bank"
          type="text"
          placeholder="Banco"
        />
        <Input
          disabled={true}
          value={accountData}
          name="account"
          type="text"
          placeholder="Conta"
        />
        <Input
          disabled={true}
          value={accountType}
          name="value"
          type="text"
          placeholder="Tipo de conta"
        />
        <Input
          disabled={true}
          value={agencyData}
          name="agency"
          type="text"
          placeholder="Agência"
        />
        <Input
          disabled={true}
          value={transferValue}
          name="value"
          type="text"
          placeholder="Valor"
        />
        {transferDescription && (
          <Input
            disabled={true}
            value={transferDescription}
            name="description"
            type="text"
            placeholder="Descrição"
          />
        )}
        {/* <FormControlLabel
          style={{ width: "100%", marginLeft: 20 }}
          control={
            <Checkbox
              checked={contactSave}
              onChange={() => {
                setContactSave(!contactSave);
              }}
              name="addContact"
              color="primary"
            />
          }
          label="Adicionar aos contatos"
        /> */}
        <Button
          action={"return"}
          style={{ width: 200, margin: 10 }}
          onClick={() => setStep(step - 1)}
        >
          Voltar
        </Button>
        <Button style={{ width: 200, margin: 10 }} type="submit">
          Finalizar transferência
        </Button>
      </Form>
    </div>
  );

  return (
    <>
      <ModalLoader
        open={bankListLoading.GET || transactionLoading.POST}
        text={
          bankListLoading.GET
            ? "Carregando informações"
            : "Finanizando transferência"
        }
      />
      <PasswordConfirm
        submitPassword={(e) => handleFinishTransaction(e)}
        open={requirePassword}
        onClose={() => setRequirePassword(false)}
      />

      {step === 0 && step0}
      {step === 1 && step1}
      {step === 2 && step2}
      {step === 3 && step3}

      <Stepper activeStep={step} alternativeLabel style={{ marginTop: 40 }}>
        {steps.map((label, index) => (
          <Step
            key={label}
            onClick={() => {
              index < step && setStep(index);
            }}
            style={{ cursor: "pointer" }}
          >
            <StepLabel color={"green"}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
};
