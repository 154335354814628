import styled from "styled-components";

export const CardComponent = styled.div`
  button {
    width: 85px;
    height: 85px;
    cursor: pointer;
    display: flex;
    align-items: center;
    border-radius: 4px;
    flex-direction: column;
    justify-content: center;
    background-color: ${(props) => props.theme.primary.original};
    padding: 5px;
    border: 0;
    gap: 10px;

    :hover {
      transform: scale(1.02);
      transition: all 0.2s ease-in-out;
    }

    svg {
      width: 24px;
      height: 24px;
    }

    p {
      color: #fff;
      text-align: center;
      font-size: 0.7rem;
      margin: 0;
    }
  }
`;
