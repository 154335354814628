import axios from "axios";
import React from "react";
import { normalizeCEPData } from "./Normalizers";

const { CancelToken } = axios;

export const useCEPData = () => {
  const [requestLoading, setRequestLoading] = React.useState(false);
  const [requestError, setRequestError] = React.useState(false);
  const [requestData, setRequestData] = React.useState({});

  const resetCEP = () => {
    setRequestData({});
  };

  const fetchCEPData = ({ cep }) => {
    if (!requestLoading) {
      setRequestError(false);
      setRequestLoading(true);

      const axiosToken = CancelToken.source();
      const cancelToken = axiosToken?.token;

      return new Promise((resolve, reject) => {
        const headers = {};

        axios
          .get(`https://viacep.com.br/ws/${cep}/json/`, {
            headers,
            cancelToken,
          })
          .then(({ data }) => normalizeCEPData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true);
            reject(err);
          })
          .finally(() => setRequestLoading(false));
      });
    }
  };

  return { requestData, requestError, requestLoading, fetchCEPData, resetCEP };
};
