import styled, { keyframes } from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;

  div {
    color: ${(props) => props.theme?.primary?.original};
  }
`;
