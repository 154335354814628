import { useEffect, useState } from "react";
import {
  Paper,
  TableCell,
  Table,
  TableBody,
  TableContainer,
  TableRow,
} from "@mui/material";

import {
  Container,
  Content,
  HeaderTable,
  StyledGrid,
  StyledGridContainer,
  TransactionsNotFoundWarn,
} from "../styles";
import { Row } from "../Row";
import { Pagination } from "../../TablePagination";
import useCallAxios from "../../../../Hooks/useCallAxios";
import { Loading } from "../../../Atoms";
import { TableFilter } from "../../Filters";
import { format } from "date-fns";
import { useFilter } from "../../../../Hooks/useFilter";

export const PaymentsTable = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [filterObject, setFilterObject] = useState({
    startDate: format(new Date(), "yyyy-MM-dd"),
    endDate: format(new Date(), "yyyy-MM-dd"),
  } as any);
  const [transactions, setTransactions] = useState({} as any);

  const context = "pixPayments";

  const {
    fetchGET: fetchPixPayments,
    requestLoading: loading,
    requestData: pixPayments,
  } = useCallAxios({ context, responseType: "" });

  const { getFilterObj } = useFilter();
  const pixPaymentFilterObj = getFilterObj("pixPayment");

  const handleSetToFirstPage = () => {
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page >= 0
      ? Math.max(0, (1 + page) * rowsPerPage - transactions?.data?.row_count)
      : 0;

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  useEffect(() => {
    fetchPixPayments({
      queryParameters: {
        pageSize: rowsPerPage,
        page: page + 1,

        ...pixPaymentFilterObj,
      },
    });
  }, [pixPaymentFilterObj, rowsPerPage, page]);

  useEffect(() => {
    setTransactions(pixPayments);
  }, [pixPayments]);

  return (
    <div>
      <TableFilter
        tableName="pixPayment"
        setPageToFirst={handleSetToFirstPage}
      />
      <StyledGridContainer container>
        <Content>
          <StyledGrid item xs={12}>
            <Container>
              <TableContainer component={Paper}>
                <Table aria-label="tabela de maquininhas de cartão">
                  <HeaderTable>
                    <TableRow>
                      <TableCell style={{ width: 10 }}></TableCell>
                      <TableCell style={{ width: "fit-content" }}>
                        Identificador PIX
                      </TableCell>
                      <TableCell>Endtoendid</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Valor</TableCell>
                      <TableCell width={90}>Data de criação</TableCell>
                    </TableRow>
                  </HeaderTable>
                  <TableBody>
                    {loading.GET ? (
                      <TableRow>
                        <TableCell
                          style={{ paddingBottom: 0, paddingTop: 0 }}
                          colSpan={6}
                        >
                          <Loading />
                        </TableCell>
                      </TableRow>
                    ) : !loading.GET &&
                      transactions?.data?.items?.length === 0 ? (
                      <TableRow>
                        <TableCell colSpan={11}>
                          <TransactionsNotFoundWarn>
                            Nenhuma transação encontrada!
                          </TransactionsNotFoundWarn>
                        </TableCell>
                      </TableRow>
                    ) : (
                      transactions?.data?.items.map((transaction, index) => {
                        return (
                          <Row
                            key={index}
                            transaction={transaction}
                            tableName={"PaymentsTable"}
                          />
                        );
                      })
                    )}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                  <Pagination
                    rowsPerPageOptions={[10, 25, 40]}
                    rowCount={transactions?.data?.row_count}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    handleChangePage={handleChangePage}
                    handleChangeRowsPerPage={handleChangeRowsPerPage}
                    label={"Transações por página"}
                  />
                </Table>
              </TableContainer>
            </Container>
          </StyledGrid>
        </Content>
      </StyledGridContainer>
    </div>
  );
};
