import { ErrorRounded, CheckCircleSharp, ArrowBack } from "@mui/icons-material";
import { useStyles } from "./styles";
import { useBalance } from "../../../Hooks/useBalance";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import ReceiptDetails from "../../../Components/Molecules/ReceiptModal/receiptDetails";

export const FinalStepPayment = ({
  paymentSuccess,
  paymentData,
  refreshStep,
}) => {
  const history = useHistory();
  const classes = useStyles();
  const { getBalance: refreshBalance } = useBalance();

  useEffect(() => {
    paymentSuccess === true && refreshBalance();
  }, []);

  return (
    <div>
      <div style={{ paddingTop: 40 }}>
        <p className={classes.backButton} onClick={refreshStep}>
          <ArrowBack></ArrowBack>
          Voltar para pagamentos
        </p>
      </div>

      {paymentSuccess === true ? (
        <>
          <h4 style={{ textAlign: "center", paddingTop: 40, fontSize: 26 }}>
            Transferência realizada com sucesso <CheckCircleSharp />
          </h4>

          <div
            style={{
              minHeight: "40%",
              paddingTop: 40,
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            <ReceiptDetails
              receipt={paymentData}
              noModal={true}
            ></ReceiptDetails>
          </div>
        </>
      ) : (
        <div
          style={{
            height: "60vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            alignItems: "center",
          }}
        >
          <h4 style={{ fontSize: 30 }}>
            Transferência Negada <ErrorRounded></ErrorRounded>{" "}
          </h4>
        </div>
      )}
    </div>
  );
};
