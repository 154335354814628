import {
  Paper,
  useMediaQuery,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import intl from "react-intl-universal";
import { ScreenGreeting } from "../../../Components/Molecules";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { DataRow, Loading } from "../../../Components/Atoms";
import { ModalLoader } from "../../../Components/Atoms/ModalLoader";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "100%",
    maxWidth: "90%",
    minHeight: "100%",
    margin: "0px auto",
  },
  paper: {
    minWidth: "100%",
    marginBottom: 20,
    marginTop: 20,
    padding: 10,
  },
}));

const DesktopExtract = ({ accountData, loading }) => {
  const classes = useStyles();

  return (
    <>
      <ModalLoader loading={loading} text="Carregando informações" />
      <div className={classes.container}>
        <ScreenGreeting
          title={intl.get(`phrases.myProfile`)}
          subTitle={intl.get(`phrases.controlAndConfigForYourAccount`)}
        />
        <Paper className={classes.paper} elevation={3}>
          <Typography style={{ padding: 15 }} variant="body2">
            DADOS
          </Typography>
          <List
            dense
            style={{
              height: "100%",
            }}
          >
            <DataRow
              title={intl.get(`phrases.fullName`)}
              value={accountData?.userName}
            />
            <DataRow
              title={intl.get(`words.email`)}
              value={accountData?.userEmail}
            />
            <DataRow
              title={intl.get(`words.phone`)}
              value={accountData?.userPhone}
            />
          </List>
        </Paper>
      </div>
    </>
  );
};

DesktopExtract.propTypes = {};

export default DesktopExtract;
