import { Hidden } from "@mui/material";
import { useCallback, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../../../Contexts/Auth";
import { useEventListener, useAccountData } from "../../../Hooks";
import { useToast } from "../../../Hooks/toast";
import { useBalance } from "../../../Hooks/useBalance";
import DesktopSkeleton from "./DesktopSkeleton";

const PrivateApp = ({ routes }) => {
  const history = useHistory();
  // const { state: accountBalanceData, handleGET: fetchAccountBalanceGET } =
  //   useAccountBalanceData();
  const { state: accountData, handleGET: fetchAccountGET } = useAccountData();
  const [, dispatchAuthContext] = useContext(AuthContext);
  const { getBalance, balanceValue, loading } = useBalance();
  const { addToast } = useToast();

  const logout = () => {
    dispatchAuthContext({
      type: "clear",
    });
    history.push("/");
  };

  const sessionDisconnect = useCallback(() => {
    logout();
    addToast({
      type: "info",
      title: "Sessão desconectada",
      description:
        "Para sua segurança você foi desconectado da aplicação, faça login novamente.",
    });
  }, []);

  useEffect(() => {
    fetchAccountGET();
    getBalance();
  }, []);

  useEventListener("error-401", sessionDisconnect);

  return (
    <>
      <DesktopSkeleton
        routes={routes}
        logout={logout}
        accountBalanceData={balanceValue}
        accountData={accountData}
        refreshBalance={getBalance}
        loadingRefreshBalance={loading}
      />
    </>
  );
};

export default PrivateApp;
