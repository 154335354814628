import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`

body, html{
  height: 100%;
  width: 100%;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  //MATERIAL UI PARTICULARIDADES
  .MuiStepIcon-root.MuiStepIcon-active {
    color:${(props) => props.theme.primary.light};
  }

  .MuiInput-underline:before{
    border-bottom: 1.5px solid #dbdfea;
  }

  .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl{
    :hover{
      background-color: transparent !important;
      border:0 !important;
    }
  }


  .MuiInputBase-root.MuiInput-root{
    color:#3c4d62;
    padding-left:15px;
    
  }

  .MuiAvatar-root{
     background-color:${(props) => props.theme.primary.original} !important;
  }

  .MuiButtonBase-root.MuiIconButton-root{
    :hover{
 border-color:${(props) => props.theme.primary.light};
    color:${(props) => props.theme.primary.light};
    }
  }

.MuiInput-underline:before{
  border-color:#cecece !important
}

  .MuiFormControl-root .MuiTextField-root{
   :hover{
        color:${(props) => props.theme.primary.light};
    }

  }  
  .MuiAutocomplete-root{
    :hover{
        .MuiFormControl-root .MuiOutlinedInput-notchedOutline{
      border-color:#dbdfea !important;
        }
    }
  }
  .MuiFormControl-root.MuiOutlinedInput-notchedOutline{
    border: 2px solid #dbdfea;
  }


   input{
    &::placeholder {
      color: #a3a3a3 !important;
    }
  }

  textarea, input { outline: none; }

  a:link {text-decoration: none}
    a:visited {text-decoration: none }
    a:active {text-decoration: none }

    ::-webkit-scrollbar {
      height: 7px;
      width: 7px;
    }

    ::-webkit-scrollbar-track {
      background: transparent;
    }
    
    ::-webkit-scrollbar-thumb {
       background: #E3E3E3; 
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background:  #E0E0E0; 
    }

  @media (max-width: 1080px) {
    html {
      font-size: 93.75%;
      
    }
  }

  @media (max-width: 720px) {
    html {
      font-size: 87.5%;
    }
  }

  body{
    background: #FFF;
  }

  input[type="date"]{
    cursor: pointer;
  }

  input[type='date']::-webkit-calendar-picker-indicator {
    filter: none;
    font-weight: bolder;
    font-size: 18px;

    :hover {
     font-size:20px;
      transition: all 0.2s ease-in-out;
      cursor: pointer;
      filter: invert(0.5) sepia(0.5) saturate(10) hue-rotate(152deg);

    }
  }



//FONTS COLOR
h2, h1, h3, h4 {
  color: ${(props) => props.theme.fonts.title};
}

h5 , h6{
    color: ${(props) => props.theme.fonts.subtitle};
}

  input, button, textarea, select{
    :disabled{
      color:    ${(props) => props.theme.fonts.disable_text};
    }
  }
 

  body,
  input,
  textarea,
  select,
  button {
    font: 400 1rem "Roboto", sans-serif;
  }

  button, a {
    cursor: pointer;
  }
  a {
    color: inherit;
    text-decoration: none;
      :hover {
        transform: scale(1.1);
        backface-visibility: hidden;
        -webkit-font-smoothing: subpixel-antialiased;
        transition: all 0.2s ease-in-out;
      }
  }
}`;
