import styled from "styled-components";
import { Avatar } from "@mui/material";

export const Container = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
`;

export const Content = styled.div`
  flex-wrap: wrap;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 14px;
  color: #444;
  cursor: pointer;

  :hover {
    transition: 0.3s;
    transform: scale(1.1);

    div:first-child {
      background-color: ${(props) => props.theme.primary.light}!important;
      transition: 0.3s;
      transform: scale(1.1);
    }
  }
`;

export const AvatarContent = styled(Avatar)`
  margin: 0 auto !important;
`;

export const ComponentTitle = styled.p`
  margin-bottom: 20px;
  font-size: 14px;
  color: ${(props) => props.theme.fonts.subtitle};
  width: 100%;
`;
