import { FormControlLabel, RadioGroup } from "@mui/material";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const Content = styled.div`
  display: flex;
  padding: 25px;
  width: 100%;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  gap: 30px;
  background-color: white;
  border-radius: 4px;
  flex-direction: column;

  form {
    min-width: 630px;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  h6 {
    font-size: 18px;
    font-weight: 400;
  }
  p {
    width: fit-content;
    color: #777;
    cursor: pointer;

    & svg {
      margin-right: 5;
      vertical-align: middle;
      font-size: 20;
    }

    :hover {
      color: #0096ff;
      transition: 1s;
    }
  }
`;

export const QRCodeDescription = styled.section`
  section {
    justify-content: start;
    margin: 0 auto;
    padding-bottom: 20px;
    display: flex;
    width: 45%;
    flex-direction: column;
    gap: 10px;

    h5 {
      font-size: 18px;
    }

    p {
      color: #9d9d9d;
    }
    ul {
      height: 100%;
      list-style: none;
      counter-reset: steps;
    }
    ul li {
      counter-increment: steps;
    }
    ul li::before {
      content: counter(steps);
      margin-right: 0.5rem;
      background: #018eff;
      color: white;
      width: 1.2em;
      height: 1.2em;
      border-radius: 50%;
      display: inline-grid;
      place-items: center;
      line-height: 1.2em;
    }
  }
`;

export const RadioGroupComponent = styled(RadioGroup)`
  width: 100% !important;
  display: flex !important;
  flex-direction: row !important;
`;

export const FormControlLabelComponent = styled(FormControlLabel)`
  align-items: center;
`;

export const CopyAndPastPixContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-start;

  span {
    font-size: 1.1em;
    color: ${(props) => props.theme.fonts.subtitle};
    line-height: 1.5em;
  }

  textarea {
    width: 100%;
    height: 100px;
    font-weight: 700;
    color: ${(props) => props.theme.primary.original};
    resize: none;
    border: 0;
  }

  button {
    width: 100%;
  }
`;
