import { Paper, useMediaQuery } from "@mui/material";
import Grid from "@mui/material/Grid";

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

import { ScreenNotification } from "../../../Components/Atoms";
import {
  ReceiptList,
  ScreenGreeting,
  UserGreeting,
} from "../../../Components/Molecules";

import { useStyles } from "./styles";
import { GreetingContainer } from "../../../Components/Molecules/PixDetails/styles";
import { PixCardsDashboard } from "../../../Components/Molecules/PixCardsDashboard";

const DesktopDashboard = ({ transactions, topNotification, accountData }) => {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width: 1025px)");

  const storage = sessionStorage.getItem("@PortalBC:isClosingBalanceShow");

  const isClosingBalance = storage?.length > 0 ? true : false;

  return (
    <div className={classes.container}>
      {!!topNotification && (
        <ScreenNotification
          icon={<ErrorOutlineIcon style={{ marginRight: 10 }} />}
          text={topNotification}
        />
      )}

      <GreetingContainer>
        <ScreenGreeting
          title={"Detalhes das suas transações"}
          subTitle={
            "Aqui você poderá visualizar todos os detalhes de suas transações"
          }
        />
      </GreetingContainer>
      {isClosingBalance && <PixCardsDashboard />}

      <div className={classes.mainContainer}>
        <div
          className={classes.paperDiv}
          style={{
            minWidth: "100%",
            margin: "10px auto",
          }}
        >
          <Grid
            container
            spacing={3}
            justifyContent="space-between"
            alignItems="center"
            style={{ padding: "20px 20px 10px 20px" }}
          ></Grid>
          <Paper
            style={{
              minWidth: matches ? "100%" : "100%",
              marginBottom: matches ? 20 : 20,
              marginTop: 20,
            }}
            elevation={3}
          >
            <ReceiptList
              headerLink="/extrato"
              itens={transactions}
              type="home"
              title="Últimas transações"
            />
          </Paper>
        </div>
      </div>
    </div>
  );
};

DesktopDashboard.propTypes = {};

export default DesktopDashboard;
