import { useEffect, useState } from "react";
import { useTheme } from "styled-components";
import { Typography } from "@mui/material";

import { Container, DateButton, DaysContainer } from "./styles";

import { ReportDownload } from "..";
import { useFilter } from "../../../Hooks/useFilter";
import { format, sub } from "date-fns";
import { comparingDates } from "../../../Utils/compareCustomFilterDateAndStaticFilterDate";

export const DateFilter = ({ setPageToFirst, tableName, hasData }) => {
  const { handleApplyFilters, getFilterObj } = useFilter();
  const filterObj = getFilterObj(tableName) as any;

  const theme = useTheme();
  const [startDate, setStartDate] = useState(
    format(sub(new Date(), { days: 7 }), "yyyy-MM-dd")
  );
  const [endDate, setEndDate] = useState(format(new Date(), "yyyy-MM-dd"));
  const [dateButtonSelected, setDateButtonSelected] = useState(7);

  const handlePeriodDateChange = (param) => {
    const { value } = param;

    setDateButtonSelected(value);
    const filterObj = {
      start_date: format(sub(new Date(), { days: value }), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
    };

    handleApplyFilters(tableName, filterObj);

    setPageToFirst();
    setEndDate(filterObj.end_date);
    setStartDate(filterObj.start_date);
  };

  useEffect(() => {
    if (
      filterObj.start_date !== undefined &&
      filterObj.end_date !== undefined &&
      filterObj.start_date !== "" &&
      filterObj.end_date !== ""
    ) {
      setDateButtonSelected(
        comparingDates(filterObj.start_date, filterObj.end_date)
      );
    }
  }, [filterObj.start_date, filterObj.end_date]);

  return (
    <Container>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="subtitle1" gutterBottom>
          Filtrar dados por período
        </Typography>

        <ReportDownload
          hasData={hasData}
          endDate={filterObj.end_date}
          startDate={filterObj.start_date}
          type={tableName}
        />
      </div>
      <DaysContainer>
        <DateButton
          style={{
            color: dateButtonSelected === 7 && "white",
            background: dateButtonSelected === 7 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 7 });
          }}
        >
          7 dias
        </DateButton>
        <DateButton
          style={{
            color: dateButtonSelected === 15 && "white",
            background: dateButtonSelected === 15 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 15 });
          }}
        >
          15 dias
        </DateButton>
        <DateButton
          style={{
            color: dateButtonSelected === 30 && "white",
            background: dateButtonSelected === 30 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 30 });
          }}
        >
          1 mês
        </DateButton>
        <DateButton
          style={{
            color: dateButtonSelected === 60 && "white",
            background: dateButtonSelected === 60 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 60 });
          }}
        >
          2 meses
        </DateButton>
        <DateButton
          style={{
            color: dateButtonSelected === 90 && "white",
            background: dateButtonSelected === 90 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 90 });
          }}
        >
          3 meses
        </DateButton>

        <DateButton
          style={{
            color: dateButtonSelected === 180 && "white",
            background: dateButtonSelected === 180 && `${theme.primary.light}`,
          }}
          onClick={() => {
            handlePeriodDateChange({ value: 180 });
          }}
        >
          6 meses
        </DateButton>
      </DaysContainer>
      <hr
        style={{
          border: "1px solid #f2f2f2",
          height: 2,
          background: "#f2f2f2",
          width: "100%",
          marginTop: 20,
        }}
      />
    </Container>
  );
};
