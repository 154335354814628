import useCallAxios from "./useCallAxios";

function useSendBatchTransfers() {
    const context = `sendBatchTransfers`;

    const { reset, requestSuccess, requestLoading, fetchPOST } =
      useCallAxios({
        context,
      });
  
    const sendTransfers = (file, items = [], passwordCombination = []) => {
      const formData = new FormData();

      formData.append('csvFile', file);

      for(let i=0; i<passwordCombination.length; i++) {
        formData.append(`passwordCombination[${i}]`, passwordCombination[i])
      }

      for(let i=0; i<items.length; i++) {
        const item = items[i];
        
        formData.append(`items[${i}].accountId`, item.accountId)
        formData.append(`items[${i}].amount`, item.amount)
        formData.append(`items[${i}].description`, item.description || '')
      }

      fetchPOST({ context, body: formData, headers: { 'Content-Type': 'multipart/form-data' } });
    };
  
    return {
      reset,
      loading: requestLoading,
      success: requestSuccess,
      sendTransfers,
    };
}

export {
    useSendBatchTransfers
}