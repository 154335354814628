import { Dialog, DialogContent } from "@mui/material";
import styled from "styled-components";
export const DialogContainer = styled(Dialog)`
  width: 100%;
`;

export const DialogBox = styled(DialogContent)`
  display: flex;
  width: 100%;
  padding: 0 !important;
  padding-top: 0 !important;
  flex-direction: column;
`;

export const Header = styled.header`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  color: white;
  background-color: #f9a000;
  font-size: 22px;

  p {
    text-align: center;
    svg {
      margin-right: 15px;
      vertical-align: sub;
    }
  }
`;

export const InformationContainer = styled.div`
  margin: 10px 0px;
  display: flex;
  align-content: center;
  width: 100%;
  padding: 20px;
  height: 200px;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  p {
    font-size: 17px;
  }

  span {
    font-weight: 700;
    color: ${(props) => props.theme.primary.original};
    font-size: 20px;
  }

  button {
    margin-top: 10px;
  }

  div {
    display: flex;
    width: 100%;
    justify-content: center;
  }
`;
