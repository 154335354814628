import { createContext, useContext, useEffect, useState } from "react";
import { normalizeAccountBalanceData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

const BalanceContext = createContext({});

export function BalanceProvider({ children }) {
  const context = `accountBalance`;
  const { reset, requestSuccess, requestData, requestLoading, fetchGET } =
    useCallAxios({
      context,
    });

  const { data } = requestData;

  const [balanceValue, setBalanceValue] = useState(() => {
    const storagedBalance = sessionStorage.getItem("@BCBankline:Balance");
    !!storagedBalance ? storagedBalance : 0;
  });

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };

  const state = normalizeAccountBalanceData(requestData);

  useEffect(() => {
    if (!!data) {
      sessionStorage.setItem("@BCBankline:Balance", data.balance);
      setBalanceValue(data.balance);
    }
  }, [data]);

  return (
    <BalanceContext.Provider
      value={{
        reset,
        loading: requestLoading,
        state,
        balanceValue,
        success: requestSuccess,
        getBalance: handleGET,
      }}
    >
      {children}
    </BalanceContext.Provider>
  );
}

export function useBalance() {
  const context = useContext(BalanceContext);
  return context;
}
