import MomentUtils from "@date-io/moment";
import { IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import moment from "moment";
import "moment/locale/pt-br";
import React, { useEffect, useRef, useState } from "react";

export const useStyles = makeStyles({
  dateContainer: {
    display: "flex",
    margin: "0px 10px",
    "& input": {
      textTransform: "capitalize",
      textAlign: "center",
    },
  },
});

moment.locale("pt-br");

export const MonthNavigation = ({ updateDateValue }) => {
  const classes = useStyles();
  const locale = "pt-br";
  const firstUpdate = useRef(true);
  const [selectedDate, setSelectedDate] = useState(moment());

  const handleDateChange = (data) => setSelectedDate(data);

  const handleAddMonth = () =>
    setSelectedDate(moment(selectedDate).add(1, "month"));

  const handleDecreaseMonth = () =>
    setSelectedDate(moment(selectedDate).subtract(1, "month"));

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (typeof updateDateValue === "function") {
      updateDateValue(selectedDate);
    }
  }, [selectedDate]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        maxWidth: 230,

        height: 60,
        margin: "0px auto",
        justifyContent: "space-between",
      }}
    >
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton color="primary" size="small" onClick={handleDecreaseMonth}>
          <ArrowBackIosIcon style={{ fontSize: 20 }} />
        </IconButton>
      </div>
      <div className={classes.dateContainer}>
        {/* <MuiPickersUtilsProvider utils={MomentUtils} locale={locale}>
          <DatePicker
            value={selectedDate}
            onChange={handleDateChange}
            animateYearScrolling
            inputVariant="outlined"
            openTo="month"
            views={["month", "year"]}
            variant="inline"
            format="MMMM/YYYY"
          />
        </MuiPickersUtilsProvider> */}
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <IconButton color="primary" size="small" onClick={handleAddMonth}>
          <ArrowForwardIosIcon style={{ fontSize: 20 }} />
        </IconButton>
      </div>
    </div>
  );
};

MonthNavigation.propTypes = {};
