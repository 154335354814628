import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const KeySelectionContainer = styled.div`
  display: flex;
  padding: 25px;
  height: 100%;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  flex-direction: column;

  div {
    justify-content: center;
  }
  form {
    width: 60%;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  
  width: 100%;
  justify-content: space-between;

  h6 {
    font-size: 18px;
    font-weight: 400;
  }
  p {
    width: fit-content;
    color: #777;
    cursor: pointer;

    & svg {
      margin-right: 5;
      vertical-align: middle;
      font-size: 20;
    }

    :hover {
      color: #0096ff;
      transition: 1s;
    }
  }
`;
