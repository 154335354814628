import React, { useEffect } from "react";
import { ToastProvider } from "../Hooks/toast";
import { BalanceProvider } from "../Hooks/useBalance";
import { CaptchaProvider } from "../Hooks/useCaptcha";

const ApplicationProvider: React.FC = ({ children }) => {
  return (
    <ToastProvider>
      <CaptchaProvider>
        <BalanceProvider>{children}</BalanceProvider>
      </CaptchaProvider>
    </ToastProvider>
  );
};

export default ApplicationProvider;
