import styled from "styled-components";
import { Button } from "@mui/material";

export const ButtonComponent = styled.button`
  width: 100%;
  cursor: pointer;
  height: auto;
  display: flex;
  border-radius: 4px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 0;
  background-color: white;
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  padding: 20px;

  svg {
    margin-right: 20px;
    width: 24px;
    color: ${(props) => props.theme.primary.original};
    height: 24px;
    vertical-align: sub;
  }

  p {
    color: ${(props) => props.theme.primary.original};
    text-align: center;
    font-size: 1rem;
    margin: 0;
  }

  :hover {
    color: ${(props) => props.theme.primary.light};
    border-color: ${(props) => props.theme.primary.light};
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;

    p,
    svg {
      color: ${(props) => props.theme.primary.light};
    }
  }
`;
