import React from "react";
import { Dialog, IconButton, Slide } from "@mui/material";
import { Container, Content, Header } from "./styles";
import { Close } from "@mui/icons-material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const PrivacyPolicyModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <Container>
        <Header>
          <h1>POLÍTICA DE PRIVACIDADE</h1>
          <div style={{ position: "absolute", right: 0 }}>
            <IconButton title="Fechar" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </div>
        </Header>
        <iframe
          style={{width:"100%", height:"500px"}}
          src="https://valut-bank-new-fparcbbvepf7d0a9.brazilsouth-01.azurewebsites.net/politica/politica.html"
          title="W3Schools Free Online Web Tutorials"
        />
      </Container>
    </Dialog>
  );
};
