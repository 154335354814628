import styled, { css } from "styled-components";
import Tooltip from "../Tooltip";

interface ContainerProps {
  isFocused: boolean;
  secondaryColor?: string;
  alwaysShow?: boolean;
  isFilled: boolean;
  width?: string;
  isErrored: boolean;
}

interface TooltipProps {
  isErrored?: boolean;
}

interface width {
  width?: string;
}

export const Container = styled.div<width>`
  background: transparent;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}
`;

export const Content = styled.div<ContainerProps>`
  background: transparent;
  color: #3c4d62;
  height: 3rem;
  align-items: center;
  border: 2px solid #dbdfea;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0.35rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;

  & + div {
    margin-top: 8px;
  }

  ${(props) =>
    props.width
      ? css`
          width: ${props.width};
        `
      : css`
          width: 100%;
        `}

  ${(props) =>
    props.isErrored &&
    css`
      border-color: ${(props) => props.theme.secondary?.secondary_05};
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border-color: ${(props) => props.theme.primary.light};
      color: ${(props) => props.theme.secondary?.secondary_05};
    `}

  ${(props) =>
    props.isFilled &&
    css`
      color: ${(props) => props.theme.primary.light}; ;
    `}

  input {
    outline: 0;
    background: transparent;
    flex: 1;
    border: 0;
    color: #3c4d62;

    &:disabled {
      color: #a3a3a3;
      cursor: not-allowed;
    }

    &::placeholder {
      color: #a3a3a3;
    }
    & + input {
      margin-top: 8px;
    }
  }

  svg {
    font-size: 22px;
  }
  button {
    :hover {
      background-color: transparent;
      color: ${(props) => props.theme.primary.light};
      transition: 1s;
    }
  }
`;

export const Error = styled.p`
  color: ${(props) => props.theme.secondary?.secondary_05};
  padding: 0.35rem 1rem;
  font-size: 0.8125rem;
  line-height: 1.25rem;
`;

export const Label = styled.label<ContainerProps>`
  font-size: 14px;
  padding-bottom: 2.5px;
  width: 100%;
  height: auto;

  ${(props) =>
    props.isErrored &&
    css`
      color: ${(props) => props.theme.secondary?.secondary_05};
    `}

  ${(props) =>
    props.isFocused || props.isFilled
      ? css`
          visibility: visible;
          color: ${(props) => props.theme.primary.light}; ;
        `
      : css`
          visibility: hidden;
        `}


          ${(props) =>
    props.alwaysShow &&
    css`
      visibility: visible;
      color: ${(props) => props.theme.primary.light}; ;
    `}
`;
