import React, {
  InputHTMLAttributes,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import { IconBaseProps } from "react-icons";
import { FiAlertCircle, FiEye, FiEyeOff } from "react-icons/fi";
import { useField } from "@unform/core";
import { Container, Content, Error, Label } from "./styles";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { relative } from "path/posix";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  type?: string;
  width?: string;
  placeholder?: string;
  containerStyle?: object;
  span?: string;
}

export const Input: React.FC<InputProps> = ({
  name,
  width,
  type,
  placeholder,
  containerStyle,
  span,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [inputType, setInputType] = useState(type);
  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(name);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    setIsFilled(!!inputRef.current?.value);
  }, []);

  const handleClickShowPassword = useCallback(() => {
    inputType === "text" ? setInputType("password") : setInputType("text");
  }, [inputType]);

  const handleMouseDownPassword = (event: any) => {
    event.preventDefault();
  };
  const handleFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
    setIsFilled(!!inputRef.current?.value);
  }, [fieldName, registerField]);

  useEffect(() => {
    if (inputRef.current?.value) setIsFilled(true);
  }, [inputRef.current]);

  return (
    <>
      <Label
        isErrored={!!error}
        isFilled={isFilled}
        isFocused={isFocused}
        alwaysShow={inputType === "date"}
      >
        {placeholder}
      </Label>
      <Container width={width}>
        <Content
          width={width}
          style={containerStyle}
          isErrored={!!error}
          isFilled={isFilled}
          isFocused={isFocused}
        >
          {span && <span>{span}</span>}

          <input
            placeholder={!isFocused ? placeholder : ""}
            type={inputType}
            onFocus={() => {
              clearError();
              handleFocus();
            }}
            defaultValue={defaultValue}
            onBlur={handleInputBlur}
            ref={inputRef}
            {...rest}
          />
          {type === "password" && (
            <IconButton
              style={{
                height: 0,
                position: "relative",
                padding: 0,
                margin: 0,
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              size="large"
            >
              {inputType === "text" ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          )}
        </Content>
        <Error>{!isFocused && error}</Error>
      </Container>
    </>
  );
};
