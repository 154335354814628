import styled, { css } from "styled-components";
import { lighten } from "polished";

interface IProps {
  isActive: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;

  span {
    font-size: 14px;
    margin: 10px;
    font-weight: 700;
    color: #b6c6e3;
  }
`;

export const Timer = styled.h4<IProps>`
  ${(props) =>
    !props.isActive &&
    css`
      visibility: hidden;
    `}
  font-size: 1.5rem;
  font-weight: 400;
  color: ${(props) => props.theme.primary.dark}; ;
`;

export const SMSResend = styled.button<IProps>`
  border: 0;
  color: ${(props) => props.theme.primary.light};
  background-color: transparent;
  margin: 5px 0px;

  ${(props) =>
    props.isActive
      ? css`
          color: ${lighten(0.3, props.theme.primary.light)};
          cursor: not-allowed;
        `
      : css`
          color: ${(props) => props.theme.primary.light};
          cursor: pointer;
          :hover {
            font-weight: 700;
            transition: 0.2s;
          }
        `}
`;
