import React, { useCallback, useEffect, useState } from "react";
import {
  Avatar,
  Dialog,
  IconButton,
  List,
  ListItemAvatar,
  ListItemText,
  Slide,
} from "@mui/material";
import { Container, Header, Content, ListItemContent } from "./styles";
import { Close } from "@mui/icons-material";
import { Button } from "../../Atoms";
import { useHistory } from "react-router";
import { Any } from "@react-spring/types";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ReturnPixModal = ({ open, onClose }) => {
  const [pixObjSelected, setPixObjSelected] = useState({});
  const history = useHistory();

  const handleSelect = useCallback(
    (value, index) => {
      if (pixObjSelected.index === index) {
        setPixObjSelected({});
      } else {
        setPixObjSelected({
          index,
          value,
        });
      }
    },
    [pixObjSelected]
  );

  const pixReturnList = [
    {
      name: "Eduardo Martins",
      bank: "Nubank",
      date: "18/01/2021",
      amount: "R$ 42,65",
      pix_key: "04827584141",
      description: "teste de transferencia saskjdksjkd",
    },
    {
      name: "Lucas user",
      bank: "Bradesco",
      date: "18/01/2021",
      amount: "R$ 75,50",
      pix_key: "04827584141",
      description: "teste de transferencia saskjdksjkd",
    },
    {
      name: "Leandro Abade",
      bank: "valut",
      date: "18/01/2021",
      amount: "R$ 15,75",
      pix_key: "04827584141",
      description: "teste de transferencia saskjdksjkd",
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} TransitionComponent={Transition}>
      <Container>
        <Header>
          <h1>Devolução de PIX</h1>
          <div style={{ position: "absolute", right: 0 }}>
            <IconButton title="Fechar" onClick={onClose} size="large">
              <Close />
            </IconButton>
          </div>
        </Header>
        <Content>
          <List
            sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
          >
            {pixReturnList.map((value, index) => (
              <ListItemContent
                isSelected={pixObjSelected.index === index}
                onClick={() => handleSelect(value, index)}
              >
                <ListItemAvatar>
                  <Avatar>
                    {!!value.name.split(" ")[0] && !!value.name.split(" ")[1]
                      ? value.name.split(" ")[0].substring(0, 1) +
                        value.name.split(" ")[1].substring(0, 1)
                      : value.name.split(" ")[0].substring(0, 1)}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={`${value.name} - ${value.bank}`}
                  secondary={`${value.amount} - ${value.date}`}
                />
              </ListItemContent>
            ))}
          </List>

          <Button
            onClick={() => history.push("/extrato")}
            disabled={Object.keys(pixObjSelected).length === 0}
          >
            Devolver pix
          </Button>
        </Content>
      </Container>
    </Dialog>
  );
};
