import { useCallback, useEffect, useRef, useState } from "react";
import { Form } from "@unform/web";
import * as Yup from "yup";
import { KeySelectionContainer } from "../../../Pages/Private/Pix/PixTransfers/styles";
import { ArrowBack } from "@mui/icons-material";
import { Button, Input } from "../../Atoms";
import { Header, KeyOptions } from "./styles";
import getValidationErrors from "../../../Utils/getValidationErrors";
import { ScreenGreeting } from "../ScreenGreeting";
import { SelectOptionButtons } from "../../Atoms/SelectOptions";
import { pixOptions } from "../../../Pages/Private/Pix/PixTransfers/pixOptions";
import { validateCPF_CNPJ } from "../../../Utils/CPF_CNPJValidation";
import {
  cleanPhoneMask,
  cleanTaxIDMask,
  handleTaxIDInput,
  phoneMask,
} from "../../../Utils";

interface SecondStepProps {
  transferAmount: number;
  handleSetStep: (step: number) => void;
  handleFetchInitialPayment: (payload: any) => void;
  loadingFetchInitialPayment: boolean;
  successFetchInitialPayment: boolean;
}

export const SecondStepPixTransfer = ({
  transferAmount,
  handleSetStep,
  handleFetchInitialPayment,
  loadingFetchInitialPayment,
  successFetchInitialPayment,
}: SecondStepProps) => {
  const [phone, setPhone] = useState("");
  const [pixOptionSelection, setPixOptionSelection] = useState("email");
  const [taxID, setTaxID] = useState("");
  const [schema, setSchema] = useState(() => Yup.object().shape({}));

  const formRef = useRef(null);

  const handleSubmitPixKey = useCallback(
    async (data) => {
      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        if (pixOptionSelection === "document") {
          data.document = cleanTaxIDMask(data.document);
        } else if (pixOptionSelection === "phone") {
          data.phone = `55${cleanPhoneMask(data.phone)}`;
        }

        let key;
        for (var index in data) {
          key = data[index];
        }

        let payload = {
          mode: "key",
          amount: transferAmount,
          key: {
            type: pixOptionSelection,
            value: key,
          },
        };

        handleFetchInitialPayment(payload);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);
          formRef.current?.setErrors(errors);
          return;
        }
      }
    },
    [schema, pixOptionSelection]
  );

  //Sets schema of the input validation yup object depending on what the user
  //chooses to insert
  useEffect(() => {
    const bollCPF_CNPJ = validateCPF_CNPJ(taxID);

    const formSchema = {
      document: Yup.object().shape({
        document: Yup.string()
          .required("CPF/CNPJ obrigatório.")
          .test("valid", "CPF/CNPJ inválido", () => bollCPF_CNPJ === true),
      }),
      email: Yup.object().shape({
        email: Yup.string()
          .email("E-mail inválido")
          .required("E-mail obrigatório"),
      }),
      randomKey: Yup.object().shape({
        randomKey: Yup.string().required("Chave obrigatória"),
      }),
      phone: Yup.object().shape({
        phone: Yup.string()
          .required("Digite o celular")
          .length(14, "Prencha o campo corretamente"),
      }),
    };

    setSchema(formSchema[pixOptionSelection]);
  }, [pixOptionSelection, taxID]);

  //Guarantees that the next step will be taken only if the
  //data sent to the API were accepted
  useEffect(() => {
    if (!!successFetchInitialPayment) handleSetStep(2);
  }, [successFetchInitialPayment]);
  return (
    <>
      <ScreenGreeting
        title={"Transferência Pix"}
        subTitle={"Insire a chave pix desejada"}
      />
      <KeySelectionContainer>
        <Header>
          <h6>Chaves PIX</h6>
          <button onClick={() => handleSetStep(0)}>
            <ArrowBack />
            Voltar
          </button>
        </Header>
        <KeyOptions>
          <SelectOptionButtons
            width={150}
            defaultOption="email"
            isSelectable
            options={pixOptions}
            refreshOptionSelected={(e) => setPixOptionSelection(e)}
          />
        </KeyOptions>
        <Form
          onSubmit={handleSubmitPixKey}
          ref={formRef}
          style={{ width: 630 }}
        >
          {pixOptionSelection === "email" && (
            <Input name={pixOptionSelection} type="text" placeholder="E-mail" />
          )}
          {pixOptionSelection === "document" && (
            <Input
              onChange={(e) => {
                setTaxID(handleTaxIDInput(e.currentTarget.value));
              }}
              value={taxID}
              name={pixOptionSelection}
              type="text"
              placeholder="CPF/CNPJ"
            />
          )}
          {pixOptionSelection === "phone" && (
            <Input
              onChange={(e) => {
                setPhone(phoneMask(e.currentTarget.value));
              }}
              value={phone}
              maxLength={14}
              name={pixOptionSelection}
              type="text"
              placeholder="Celular"
            />
          )}
          {pixOptionSelection === "randomKey" && (
            <Input
              name={pixOptionSelection}
              type="text"
              placeholder="Chave aleatória"
            />
          )}
          <Button type="submit" loading={!!loadingFetchInitialPayment}>
            Continuar
          </Button>
        </Form>
      </KeySelectionContainer>
    </>
  );
};
