import styled, { css } from "styled-components";
import { animated } from "react-spring";

interface ContainerProps {
  type?: "success" | "error" | "info" | "";
  hasdescription?: number;
}
const toastTypeVariations = {
  info: css`
    background: ${(props) => props.theme.feedback.info_bg};
    color: ${(props) => props.theme.feedback.info};
  `,
  success: css`
    background: ${(props) => props.theme.feedback.success_bg};
    color: ${(props) => props.theme.feedback.success};
  `,
  error: css`
    background: ${(props) => props.theme.feedback.error_bg};
    color: ${(props) => props.theme.feedback.error};
  `,
  warning: css`
    background: ${(props) => props.theme.feedback.warning_bg};
    color: ${(props) => props.theme.feedback.warning};
  `,
};

export const Container = styled(animated.div)<ContainerProps>`
  width: 330px;
  position: relative;
  z-index: 10000;
  padding: 16px 30px 16px 16px;
  border-radius: 10px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  & + div {
    margin-top: 8px;
  }
  ${(props) => toastTypeVariations[props.type || "info"]}

  > svg {
    margin: 4px 12px 4px 4px;
  }
  div {
    flex: 1;
    p {
      width: 100%;
      margin-top: 4px;
      font-size: 14px;
      opacity: 0.8;
      line-height: 20px;
    }
  }
  button {
    position: absolute;
    top: 19px;
    right: 16px;
    opacity: 0.6;
    border: 0;
    background: transparent;
    color: inherit;
  }

  ${(props) =>
    !props.hasdescription &&
    css`
      align-items: center;

      svg {
        margin-top: 0;
      }
    `}
`;
