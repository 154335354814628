import {
  Box,
  List,
  InputLabel,
  ListItem,
  OutlinedInput,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
  FormControl,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CloseRounded } from "@mui/icons-material";
import { useContext, useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import ReceiptModal from "../ReceiptModal";
import { Link } from "react-router-dom";
import { ModalLoader } from "../../Atoms/ModalLoader";
import { Pagination } from "@mui/lab";
import moment from "moment";
import {
  formatCurrency,
  formatCurrencyColor,
  formatCurrencyType,
} from "../../../Utils/formatters";
import { AuthContext } from "../../../Contexts/Auth";
import { UserContext } from "../../../Contexts/User";

export const ReceiptList = ({
  itens,
  loading,
  type,
  hasFilter,
  title,
  headerLink,
  handleChangePage,
  page,
}) => {
  const useStyles = makeStyles({
    list: {
      paddingBottom: 0,
      borderRadius: 5,
      overflowY: "auto",
      minHeight: 50,
      maxHeight: "100%",
    },
  });

  const matches = useMediaQuery("(min-width: 460px)");
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState({});
  const [inputFilterValue, setInputFilterValue] = useState("");
  const [viewReceiptDetails, setViewReceiptDetails] = useState(false);

  const storage = sessionStorage.getItem("@PortalBC:isClosingBalanceShow");

  const isClosingBalance = storage?.length > 0 ? true : false;

  const handleClickItem = (item) => {
    setSelectedItem(item?.receipt);
    setViewReceiptDetails(true);
  };

  const handleCloseDetails = () => {
    setViewReceiptDetails(false);
    setSelectedItem({});
  };

  if (loading) {
    return <ModalLoader open={loading} text={"Carregando informações"} />;
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          padding: headerLink ? "30px 20px" : 0,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {!!headerLink && (
          <>
            <p
              style={{
                fontSize: 20,
                color: "#595961",
                fontWeight: 400,
              }}
            >
              {title}
            </p>
            <Link
              to={headerLink}
              style={{
                fontSize: 16,
              }}
            >
              Ver mais
            </Link>
          </>
        )}
      </div>

      <List dense className={classes.list}>
        {itens.items?.length === 0 ? (
          <Box component="span" display="flex" justifyContent="center" m={1}>
            Nenhum resultado encontrado.
          </Box>
        ) : (
          itens.items?.map((receipt) => {
            if (receipt) {
              const day = Object.keys(receipt)[0];
              const listReceipt = receipt[day];

              return (
                <>
                  {listReceipt.map((item, index) => {
                    const el = {
                      ...item,
                      title: item?.description,
                      date: moment(item?.created_at).format(
                        "DD/MM/YYYY [às] HH:mm"
                      ),
                      dayDate: moment(item?.created_at).format("DD/MM/YYYY"),
                      value: formatCurrency(Math.abs(item?.amount)),
                      balance_after: formatCurrency(
                        Math.abs(item?.balance_after)
                      ),
                      balance_before: formatCurrency(
                        Math.abs(item?.balance_before)
                      ),
                      valueColor: formatCurrencyColor(item?.amount),
                      type: formatCurrencyType(item?.amount),
                      icon: item?.category?.icon,
                    };
                    return (
                      <>
                        {isClosingBalance && index === 0 ? (
                          <ListItem
                            style={{
                              borderBottom: "2px dashed #e0e0e0",
                              padding: 20,
                              flexDirection: matches ? "row" : "column",
                            }}
                          >
                            <ListItemText
                              primary={<strong>Saldo inicial do dia</strong>}
                              secondary={
                                <Typography variant="body1">
                                  {el.dayDate}
                                </Typography>
                              }
                              style={{
                                paddingTop: "5px",
                                height: "100%",
                              }}
                            />
                            <ListItemSecondaryAction
                              style={{
                                justifyContent: "start",
                                top: "70%",
                              }}
                            >
                              <Typography variant="body1">
                                <strong>{el.balance_before}</strong>
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : null}
                        <ListItem
                          key={index.toString()}
                          style={{
                            cursor: type === "receipts" ? "pointer" : "default",
                            borderBottom: "1px solid #e0e0e0",
                            padding: 20,
                            flexDirection: matches ? "row" : "column",
                          }}
                          onClick={() =>
                            type === "receipts" ? handleClickItem(el) : ""
                          }
                        >
                          <ListItemIcon
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                              padding: 0,
                            }}
                          >
                            <img src={el?.icon} height={30} width="auto" />
                          </ListItemIcon>
                          <ListItemText
                            primary={<strong>{el?.title}</strong>}
                            secondary={
                              <Typography variant="body2">
                                {el?.date}
                              </Typography>
                            }
                            style={{
                              paddingTop: "5px",
                              height: "100%",
                            }}
                          />
                          <ListItemSecondaryAction
                            style={{
                              justifyContent: "start",
                              top: "70%",
                            }}
                          >
                            <Typography
                              variant="body1"
                              style={{ color: el?.valueColor }}
                            >
                              {el?.value}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItem>
                        {isClosingBalance &&
                        index === listReceipt.length - 1 ? (
                          <ListItem
                            style={{
                              borderBottom: "2px dashed #e0e0e0",
                              padding: 20,
                              flexDirection: matches ? "row" : "column",
                            }}
                          >
                            <ListItemText
                              primary={<strong>Saldo final do dia</strong>}
                              secondary={
                                <Typography variant="body1">
                                  {el.dayDate}
                                </Typography>
                              }
                              style={{
                                paddingTop: "5px",
                                height: "100%",
                              }}
                            />
                            <ListItemSecondaryAction
                              style={{
                                justifyContent: "start",
                                top: "70%",
                              }}
                            >
                              <Typography variant="body1">
                                <strong>{el.balance_after}</strong>
                              </Typography>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ) : null}
                      </>
                    );
                  })}
                </>
              );
            }
            return <></>;
          })
        )}
      </List>
      {(type === "extract" || type === "receipts") &&
        itens.items?.length > 0 && (
          <Box mt={2} mb={1} ml={2}>
            <Pagination
              count={itens.pageCount}
              page={page}
              onChange={handleChangePage}
              variant="outlined"
              shape="rounded"
              size="large"
            />
          </Box>
        )}
      {type === "receipts" && (
        <ReceiptModal
          type={type}
          open={viewReceiptDetails}
          onClose={handleCloseDetails}
          selectedItem={selectedItem}
        />
      )}
    </>
  );
};

ReceiptList.propTypes = {};
