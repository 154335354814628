import { useEffect } from "react";
import { ThemeProvider } from "styled-components";
import ApplicationProvider from "./Contexts/Application";
import AuthProvider from "./Contexts/Auth";
import UserProvider from "./Contexts/User";
import GlobalStyle from "./Pages/Public/styles/GlobalStyle";
import Routes from "./Routes";
import Notifications from "./Wrappers/Notifications";
import Translations from "./Wrappers/Translations";
import { useGetSiteOptions } from "./Hooks/WhiteLabel";
import { WhiteLabelLoading } from "./Components/Atoms/WhiteLabelLoading";
import { SessionTimerProvider } from "./Hooks/useSessionTimer";
import { FilterProvider } from "./Hooks/useFilter";

const App = () => {
  const { data, handleGET: fetchSiteOptions } = useGetSiteOptions();

  useEffect(() => {
    fetchSiteOptions();
    if ("caches" in window) {
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach((name) => {
          caches.delete(name);
        });
      });
    }
  }, []);

  const faviconElement = document.getElementById("favicon");
  faviconElement.href = data?.favicon ?? "";

  const titleElement = document.getElementById("title");
  titleElement.innerHTML = data?.title ?? "Sua carteira digital";

  return !!data ? (
    <ThemeProvider theme={data?.colors}>
      <AuthProvider>
        <ApplicationProvider>
          <UserProvider>
            <Translations>
              <Notifications>
                <SessionTimerProvider>
                  <FilterProvider>
                    <Routes />
                    <GlobalStyle />
                  </FilterProvider>
                </SessionTimerProvider>
              </Notifications>
            </Translations>
          </UserProvider>
        </ApplicationProvider>
      </AuthProvider>
    </ThemeProvider>
  ) : (
    <WhiteLabelLoading></WhiteLabelLoading>
  );
};

export default App;
