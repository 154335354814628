import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { handleTaxIDInput } from "../../../Utils";
import {
  convertToCents,
  convertToUnity,
  formatCurrency,
} from "../../../Utils/formatters";
import { Button } from "../../Atoms";
import { InputMoney } from "../../Atoms/InputMoney";
import { ScreenGreeting, PasswordConfirm } from "../../Molecules";
import { DataContent } from "./styles";
import { useToast } from "../../../Hooks/toast";

export const PixPreview = ({
  isCustomValue,
  previewData,
  finishTransaction,
  cancelOperation,
  isLoading,
}) => {
  const [amountValue, setAmountValue] = useState(0);
  const [requirePassword, setRequirePassword] = useState(false);
  const { addToast } = useToast();
  const [document, setDocument] = useState(true);

  useEffect(() => {
    let doc = "";
    if (previewData?.receiver.document) {
      doc = handleTaxIDInput(previewData?.receiver.document);

      if (doc.length > 14) {
        doc = "••" + doc.slice(2, 16) + "••";
      } else {
        doc = "•••" + doc.slice(3, 12) + "••";
      }

      setDocument(doc);
    }
  }, [previewData]);

  const handleConfirmPixPayment = useCallback(
    (pass) => {
      if (amountValue <= 0 && previewData.amount <= 0) {
        addToast({
          title: "Adicione um valor!",
          type: "error",
          description: "Não se esqueça de adicionar um valor à transação.",
        });
        return;
      }
      let payload = {
        validation: {
          password_combination: pass,
        },

        amount: isCustomValue
          ? convertToCents(amountValue)
          : previewData.amount,
        pix_id: previewData?.pix_id,
      };

      finishTransaction(payload);
      setRequirePassword(false);
    },
    [previewData, amountValue]
  );

  return (
    <>
      <ScreenGreeting
        title={"Transferência Pix"}
        subTitle={
          "Confirme os dados do benefeciado antes de confirmar o pagamento."
        }
      />
      <DataContent>
        <p>Valor da transferência</p>

        {isCustomValue ? (
          <InputMoney
            hasInput={false}
            refreshValue={(value) => {
              setAmountValue(value);
            }}
          />
        ) : (
          <h3>{formatCurrency(previewData.amount)}</h3>
        )}
        <hr></hr>
        <p>Pagamento para</p>
        <span>{previewData?.receiver.name}</span>
        <p>CPF</p>
        <span>{document}</span>
        <p>Identificador</p>
        <span>{previewData?.pix_id}</span>
        <p>Banco</p>
        <span>{previewData?.receiver.bank_name}</span>
        <p>Data</p>
        <span>{moment().format("DD/MM/YYYY")}</span>
        <hr></hr>
        <div>
          <Button
            action={"cancel"}
            onClick={cancelOperation}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button
            disabled={isLoading}
            onClick={() => {
              setRequirePassword(true);
            }}
          >
            Pagar
          </Button>
        </div>
      </DataContent>
      <PasswordConfirm
        submitPassword={(e) => handleConfirmPixPayment(e)}
        open={requirePassword}
        onClose={() => setRequirePassword(false)}
      />
    </>
  );
};
