import { useEffect, useRef, useState } from "react";
import { useReportCSV, useReportExcel } from "../../../Hooks";
import downloadFile from "../../../Utils/downloadCSVFile";
import { useToast } from "../../../Hooks/toast";
import { useFilter } from "../../../Hooks/useFilter";
import {
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { ArrowDropDownCircleOutlined } from "@mui/icons-material";
import { ReportButton } from "./styles";
import { Loading } from "../../Atoms";

const options = ["Baixar relatório (Excel)", "Baixar relatório (CSV)"];

export const ReportDownload = ({ startDate, endDate, type, hasData }) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const {
    handleGET: fetchReportExcel,
    loading: loadingExcel,
    state: reportExcel,
  } = useReportExcel();
  const {
    handleGET: fetchReportCSV,
    loading: loadingCSV,
    state: reportCSV,
  } = useReportCSV();

  const { addToast } = useToast();

  const { getFilterObj } = useFilter();
  const extractFilterObj: any = getFilterObj("extract");

  const { start_date, end_date } = extractFilterObj;

  const handleDownloadReport = () => {
    const optionSelected = options[selectedIndex];

    if (optionSelected === "Baixar relatório (Excel)") {
      try {
        fetchReportExcel({ ...extractFilterObj });
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro ao baixar informações",
          description:
            "Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.",
        });
      }
    } else {
      try {
        fetchReportCSV({ ...extractFilterObj });
      } catch (err) {
        addToast({
          type: "error",
          title: "Erro ao baixar informações",
          description:
            "Ocorreu um erro ao baixar a planilha, tente novamente mais tarde.",
        });
      }
    }
  };

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: Event) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (Object.keys(reportExcel).length > 0) {
      let fileName = `extrato(${start_date}_${end_date}).xls`;
      downloadFile(fileName, reportExcel, "excel");
    }
  }, [reportExcel]);

  useEffect(() => {
    if (Object.keys(reportCSV).length > 0) {
      let fileName = `extrato(${start_date}_${end_date}).csv`;
      downloadFile(fileName, reportCSV, "csv");
    }
  }, [reportCSV]);

  return (
    type === "extract" && (
      <>
        <ButtonGroup
          variant="contained"
          ref={anchorRef}
          aria-label="split button"
        >
          <ReportButton
            onClick={handleDownloadReport}
            disabled={loadingExcel.GET || loadingExcel.GET}
          >
            {loadingExcel.GET || loadingExcel.GET ? (
              <Loading />
            ) : (
              options[selectedIndex]
            )}
          </ReportButton>
          <ReportButton
            aria-controls={open ? "split-button-menu" : undefined}
            aria-expanded={open ? "true" : undefined}
            aria-label="select merge strategy"
            aria-haspopup="menu"
            onClick={handleToggle}
          >
            <ArrowDropDownCircleOutlined />
          </ReportButton>
        </ButtonGroup>
        <Popper
          sx={{
            zIndex: 1,
          }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
    )
  );
};
