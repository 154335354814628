import { FormEvent, useState } from "react";

import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { format, isAfter, isBefore, sub } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { MenuItem, Stack } from "@mui/material";
import {
  ButtonsWrapper,
  DatePickerContainer,
  DatePickerField,
  Field,
  FlexDiv,
  FlexDivCustom,
  Form,
  StyledTextField,
} from "../styles";
import { formatInputValue } from "../../../../../Utils/formatInputValue";
import { simpleConvertToCents } from "../../../../../Utils/formatters";
import { useToast } from "../../../../../Hooks/toast";
import { handleTaxIDInput } from "../../../../../Utils";
import { useFilter } from "../../../../../Hooks/useFilter";
import { validateDate } from "../../../../../Utils/validateDate";

interface TicketsFilterProps {
  tableName: string;
  setPageToFirst: () => void;
}

export const TicketsFilter = ({
  tableName,
  setPageToFirst,
}: TicketsFilterProps) => {
  const { getFilterObj, handleApplyFilters, handleRemoveFilters } = useFilter();

  const filterObj = getFilterObj(tableName) as any;

  const { addToast } = useToast();

  const [startDate, setStartDate] = useState<Date | null>(() => {
    return filterObj?.start_date !== null &&
      filterObj?.start_date !== undefined &&
      filterObj?.start_date !== ""
      ? new Date(filterObj.start_date.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });
  const [endDate, setEndDate] = useState<Date | null>(() => {
    return filterObj?.end_date !== null &&
      filterObj?.end_date !== undefined &&
      filterObj?.end_date !== ""
      ? new Date(filterObj.end_date.replace(/-/g, "/").replace(/T.+/, ""))
      : new Date();
  });
  const [dueStartDate, setDueStartDate] = useState<Date | null>(() => {
    return filterObj?.due_date_start !== null &&
      filterObj?.due_date_start !== undefined &&
      filterObj?.due_date_start !== ""
      ? new Date(filterObj.due_date_start.replace(/-/g, "/").replace(/T.+/, ""))
      : null;
  });
  const [dueEndDate, setDueEndDate] = useState<Date | null>(() => {
    return filterObj?.due_date_end !== null &&
      filterObj?.due_date_end !== undefined &&
      filterObj?.due_date_end !== ""
      ? new Date(filterObj.due_date_end.replace(/-/g, "/").replace(/T.+/, ""))
      : null;
  });
  const [startAmount, setStartAmount] = useState<number | null>(null);
  const [endAmount, setEndAmount] = useState<number | null>(null);
  const [document, setDocument] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("");

  const statusTypes = [
    {
      value: "opened",
      label: "Em aberto",
    },
    {
      value: "canceled",
      label: "Cancelados",
    },
    {
      value: "paid",
      label: "Pagos",
    },
  ];

  const handleCustomStartDateChange = (date) => {
    setStartDate(date);
  };

  const handleCustomEndDateChange = (date) => {
    setEndDate(date);
  };
  const handleCustomDueStartDateChange = (date) => {
    setDueStartDate(date);
  };

  const handleCustomDueEndDateChange = (date) => {
    setDueEndDate(date);
  };

  const handleDeleteFilters = () => {
    if (Object.keys(filterObj).length === 0) {
      addToast({
        title: "Nenhum filtro aplicado.",
        description: "Não tem filtro aplicado para ser removido.",
        type: "info",
      });
      return;
    }

    handleRemoveFilters(tableName, {
      start_date: format(sub(new Date(), { days: 7 }), "yyyy-MM-dd"),
      end_date: format(new Date(), "yyyy-MM-dd"),
      document: "",
      start_amount: null,
      end_amount: null,
      name: name,
      due_date_start: null,
      due_date_end: null,
      type: "",
    });
    setStartDate(sub(new Date(), { days: 7 }));
    setEndDate(new Date());
    setDueStartDate(null);
    setDueEndDate(null);
    setStartAmount(null);
    setEndAmount(null);
    setDocument("");
    setName("");
    setType("");
  };

  const handleSubmitForm = (e: FormEvent) => {
    e.preventDefault();

    const queryParams: any = {
      start_date: startDate === null ? "" : format(startDate, "yyyy-MM-dd"),
      end_date: endDate === null ? "" : format(endDate, "yyyy-MM-dd"),
      document: document,
      start_amount:
        startAmount === null ? "" : simpleConvertToCents(startAmount),
      end_amount: endAmount === null ? "" : simpleConvertToCents(endAmount),
      name: name,
      due_date_start:
        dueStartDate === null ? "" : format(dueStartDate, "yyyy-MM-dd"),
      due_date_end: dueEndDate === null ? "" : format(dueEndDate, "yyyy-MM-dd"),
      type: type,
    };

    const hasValue = Object.keys(queryParams).some((key) => !!queryParams[key]);

    //verifica se algum filtro foi aplicado
    if (!hasValue) {
      addToast({
        type: "info",
        title: "Nenhum filtro foi aplicado!",
        description: "Por favor insira algum parâmetro.",
      });

      return;
    }

    //Verifica se o filtro ja foi aplicado
    if (JSON.stringify(queryParams) === JSON.stringify(filterObj)) {
      addToast({
        type: "info",
        title: "Filtro já aplicado!",
        description: "Por favor insira outros parâmetros.",
      });

      return;
    }

    //Verifica se as datas são válidas
    const validatedDates = validateDate(
      queryParams.start_date,
      queryParams.end_date
    );
    const validatedDueDates = validateDate(
      queryParams.due_date_start,
      queryParams.due_date_end
    );
    if (
      validatedDates === "invalid date" ||
      validatedDueDates === "invalid date"
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Por favor insira uma data válida.",
      });

      return;
    }

    //Verifica se a data inicial não é maior que a data final
    if (
      validatedDates === "invalid initialDate" ||
      validatedDueDates === "invalid initialDate"
    ) {
      addToast({
        type: "info",
        title: "Data Inválida!",
        description: "Data inicial não pode ser maior que data final.",
      });

      return;
    }

    handleApplyFilters(tableName, queryParams);
    setPageToFirst();
  };

  return (
    <Form onSubmit={handleSubmitForm}>
      <FlexDivCustom>
        <Field>
          <StyledTextField
            id="payerName"
            label="Pagador"
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
            name="name"
            type="text"
          />
        </Field>

        <Field>
          <StyledTextField
            id="document"
            label="Documento"
            value={document}
            onChange={(e) => {
              setDocument(handleTaxIDInput(e.target.value));
            }}
            name="payerDocument"
            type="text"
          />
        </Field>
        <Field>
          <StyledTextField
            id="status"
            select
            label="Status"
            value={type}
            onChange={(e) => {
              setType(e.target.value);
            }}
          >
            <MenuItem value=""></MenuItem>
            {statusTypes?.map((type, index) => (
              <MenuItem key={index} value={type?.value}>
                {type?.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Field>
      </FlexDivCustom>
      <FlexDivCustom>
        <Field>
          <StyledTextField
            id="startAmount"
            label="Valor Min."
            value={startAmount === null ? "" : startAmount}
            onChange={(e) => {
              setStartAmount(formatInputValue(e.target.value));
            }}
            name="startAmount"
            type="text"
            placeholder="Valor (R$)"
            aria-label="minAmount-input"
          />
        </Field>
        <Field>
          <StyledTextField
            id="endAmount"
            variant={"outlined"}
            label={"Valor Máx."}
            value={endAmount === null ? "" : endAmount}
            onChange={(e) => {
              setEndAmount(formatInputValue(e.target.value));
            }}
            name="endAmount"
            type="text"
            placeholder="Valor (R$)"
            aria-label="maxAmount-input"
          />
        </Field>
      </FlexDivCustom>
      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <Stack
            direction="row"
            style={{ gap: "2.2rem" }}
            alignItems={"center"}
          >
            <Field>
              <DesktopDatePicker
                label="Data inicial"
                value={startDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomStartDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
            <Field>
              <DesktopDatePicker
                label="Data final"
                value={endDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomEndDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
          </Stack>
        </LocalizationProvider>
      </DatePickerContainer>
      <DatePickerContainer>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <Stack
            direction="row"
            style={{ gap: "2.2rem" }}
            alignItems={"center"}
          >
            <Field>
              <DesktopDatePicker
                label="Data inicial de vencimento"
                value={dueStartDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomDueStartDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
            <Field>
              <DesktopDatePicker
                label="Data final de vencimento"
                value={dueEndDate}
                minDate={sub(new Date(), { years: 1 })}
                maxDate={new Date()}
                onChange={handleCustomDueEndDateChange}
                renderInput={(params: any) => <DatePickerField {...params} />}
              />
            </Field>
          </Stack>
        </LocalizationProvider>
      </DatePickerContainer>
      <ButtonsWrapper>
        <button type="submit">Aplicar filtros</button>
        <button type="button" onClick={handleDeleteFilters}>
          Remover filtros
        </button>
      </ButtonsWrapper>
    </Form>
  );
};
