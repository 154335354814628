import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  max-width: 1340px;

  margin-bottom: 2rem;
`;

export const DateInfo = styled.p`
  font-size: 0.8em;
  font-weight: bold;
  color: gray;

  text-align: left;

  // text-transform: uppercase;
`;

export const CardsWrapper = styled.div`
  display: flex;
  align-items: center;
  // justify-content: space-evenly;
  gap: 1rem;
  flex-wrap: wrap;

  height: 100%;
  width: 100%;
`;

export const Card = styled.div`
  width: 30%;
  height: 140px;

  padding: 1rem 1.5rem;
  font-weight: bold;
  color: #595959;

  border-radius: 0.5rem;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.12);

  p {
    white-space: normal;
    text-transform: uppercase;
  }
`;

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70%;

  margin-top: 0.5rem;

  p {
    font-size: 1.5em;
    color: ${(props) => props.theme.background.darkest};
  }
`;

export const LabelsContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;

  div p {
    font-size: 0.6em;
  }

  div {
    position: relative;

    &::before {
      content: "";
      position: absolute;
      top: 0rem;
      left: -0.7rem;

      width: 10px;
      height: 10px;
      background: #24ff24;
    }
  }
`;

export const ChartWrapper = styled.div`
  width: 100px;
  height: 100px;

  canvas {
    width: 100px;
  }
`;
