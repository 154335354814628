import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
  useMediaQuery,
  Box,
} from "@mui/material";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { useTheme } from "styled-components";

export const ContactsList = ({ itens }) => {
  const matches = useMediaQuery("(min-width: 460px)");
  const theme = useTheme();

  return (
    <>
      <List
        dense
        style={{
          border: "1px solid lightGray",
          borderRadius: 5,
          height: "100%",
        }}
      >
        {itens?.length === 0 ? (
          <Box component="span" display="flex" justifyContent="center" m={1}>
            Nenhum resultado encontrado.
          </Box>
        ) : (
          itens?.map((el, index) => {
            return (
              <ListItem
                key={index.toString()}
                style={{
                  borderBottom: "1px solid lightGray",
                  height: matches ? 65 : "100%",
                  padding: 15,
                  flexDirection: matches ? "row" : "column",
                }}
              >
                <div
                  style={{
                    borderRadius: "50%",
                    border: `2px solid  ${theme.primary.light};`,
                    width: 40,
                    background: "#CCEAFF",
                    height: 40,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    marginRight: 20,
                  }}
                >
                  <span
                    style={{
                      color: theme.primary.light,
                    }}
                  >
                    {!!el.name.split(" ")[0] && !!el.name.split(" ")[1]
                      ? el.name.split(" ")[0].substring(0, 1) +
                        el.name.split(" ")[1].substring(0, 1)
                      : el.name.split(" ")[0].substring(0, 1)}
                  </span>
                </div>
                <ListItemText
                  primary={<strong>{el?.name}</strong>}
                  secondary={
                    <Typography variant="subtitle2">{`${el?.email}`}</Typography>
                  }
                  style={{
                    height: "100%",
                  }}
                />
                <ListItemSecondaryAction
                  style={{
                    height: "100%",
                    paddingTop: 10,
                  }}
                >
                  <IconButton color="primary" size="small" component="span">
                    <MoreHorizIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            );
          })
        )}
      </List>
    </>
  );
};

ContactsList.propTypes = {};
