import styled from "styled-components";

export const Container = styled.div`
  width: 120px;
  height: 30px;
  background-color: white;
  color: ${(props) => props.theme.background.original};
  border: 2px solid ${(props) => props.theme.background.original};
  border-radius: 4px;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    word-break: break-all;
  }

  span {
    font-size: 0.9em;
  }

  svg {
    cursor: pointer;
    font-size: 0.9em;
  }
`;
