import { staticTransfers } from "../Constants/Mock";
import { normalizeTransfersData } from "./Normalizers";
import useCallAxios from "./useCallAxios";

const filterQuery = (values) => ({ ...values });

export const useTransfersData = () => {
  const context = `transferStatement`;
  const {
    reset,
    requestSuccess,
    requestData,
    requestLoading,
    fetchGET,
    requestMessage,
  } = useCallAxios({
    context,
  });

  const handleGET = (params) => {
    fetchGET({ context, queryParameters: filterQuery(params) });
  };
  //const state = normalizeTransfersData(requestData);
  return {
    reset,
    message: requestMessage,
    loading: requestLoading,
    state: requestData.data,
    success: requestSuccess,
    handleGET,
  };
};
