import React, { useEffect } from "react";
import { Modal, Slide } from "@mui/material";
import { Container, Content } from "./styles";
import { Typography } from "@mui/material";
import TypeWriter from "typewriter-effect";
import { CircularProgress } from "@mui/material";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export const ModalLoader = ({ open, text }) => {
  //PREVENT SCROLL

  return (
    <Modal open={open}>
      <Content>
        <CircularProgress style={{ verticalAlign: "sub", color: "white" }} />
        <Typography variant="h5">
          <TypeWriter
            options={{
              strings: [text],
              autoStart: true,
              loop: true,
            }}
          />
        </Typography>
      </Content>
    </Modal>
  );
};
