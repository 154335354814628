import {
  accountRoute,
  dashboardRoute,
  defaultRoute,
  extractRoute,
  // contactsRoute,
  pixRoute,
  pixPaymentRoute,
  pixSales,
  pixDetails,
  forgotPassword,
  loginRoute,
  receiptRoute,
  transferRoute,
  refoundsRoute,
  // ticketsRoute,
  pixTransferRoute,
  tokenVerificationRoute,
  paymentsRoute,
} from "./Routes";

export const physicalPerson = {
  private: [
    dashboardRoute,
    extractRoute,
    pixRoute,
    pixSales,
    pixDetails,
    refoundsRoute,
    transferRoute,
    paymentsRoute,
    // ticketsRoute,
    pixTransferRoute,
    receiptRoute,
    pixPaymentRoute,
    // contactsRoute,
    accountRoute,
    defaultRoute,
  ],
  public: [tokenVerificationRoute, forgotPassword, loginRoute],
};

export const menuRoutes = [
  dashboardRoute,
  extractRoute,
  pixRoute,
  transferRoute,
  refoundsRoute,
  // contactsRoute,
  paymentsRoute,
  // ticketsRoute,

  receiptRoute,
  accountRoute,
];

export const companyPerson = {
  private: [
    dashboardRoute,
    extractRoute,

    pixRoute,
    transferRoute,
    refoundsRoute,
    paymentsRoute,
    pixTransferRoute,
    pixSales,
    pixDetails,
    pixPaymentRoute,
    receiptRoute,
    // ticketsRoute,
    // contactsRoute,
    accountRoute,
    defaultRoute,
  ],
  public: [tokenVerificationRoute, forgotPassword, loginRoute],
};
