import { createContext, useContext, useEffect, useState } from "react";

interface SessionTimerContextData {
  handleSetTimer: (dateTimerExpire: string) => void;
  getReturnValues: () => any[];
}

const SessionTimerContext = createContext<SessionTimerContextData>(
  {} as SessionTimerContextData
);

const SessionTimerProvider = ({ children }) => {
  const [countDown, setCountDown] = useState<number>(() => {
    //this guarantees timer will be the same even after user reloads page
    const storagedSessionTimer =
      typeof window !== "undefined"
        ? sessionStorage.getItem("@Session:expires")
        : null;

    if (storagedSessionTimer) {
      return JSON.parse(storagedSessionTimer) - new Date().getTime();
    }

    return 0;
  });

  const [countDownDate, setCountDownDate] = useState<number>(() => {
    //this guarantees timer will be the same even after user reloads page
    const storagedSessionTimer =
      typeof window !== "undefined"
        ? sessionStorage.getItem("@Session:expires")
        : null;

    if (storagedSessionTimer) {
      return JSON.parse(storagedSessionTimer);
    }

    return 0;
  });

  const handleSetTimer = (dateTimerExpire: string) => {
    const dateTime = new Date(dateTimerExpire).getTime();
    sessionStorage?.setItem("@Session:expires", JSON.stringify(dateTime));

    setCountDownDate(dateTime);

    setCountDown(dateTime - new Date().getTime());
  };

  const getReturnValues = () => {
    // calculate time left
    const minutes = Math.floor((countDown % (((1000 * 60 * 60)) / (1000 * 60))*60));
    const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

    return [minutes, seconds];
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return (
    <SessionTimerContext.Provider value={{ handleSetTimer, getReturnValues }}>
      {children}
    </SessionTimerContext.Provider>
  );
};

function useSessionTimer() {
  const context = useContext(SessionTimerContext);

  return context;
}

export { SessionTimerProvider, useSessionTimer };
