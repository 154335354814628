import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 90%;
  min-height: 100%;
  margin: 0px auto;
`;

export const FilterContainer = styled.div`
  margin-bottom: 1rem;
`;

export const ContentContainer = styled.div`
  padding: 1rem;
`;

export const PaperDiv = styled.div`
  margin: 20;
`;

export const PaperHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10;
  padding: 25;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ListContainer = styled.div`
  padding: 0px 10px 10px 10px;
`;

// export const useStyles = makeStyles((theme) => ({
//   container: {
//     width: "100%",
//     maxWidth: "90%",
//     minHeight: "100%",
//     margin: "0px auto",
//   },
//   formControl: {
//     width: 200,
//   },
//   paperDiv: {
//     margin: 20,
//     minWidth: "100%",
//   },
//   paperHeader: {
//     width: "100%",
//     display: "flex",
//     flexDirection: "row",
//     flexWrap: "wrap",
//     gap: 10,
//     padding: 25,
//     height: "100%",
//     alignItems: "center",
//     justifyContent: "space-between",
//   },
//   listContainer: {
//     padding: "0px 10px 10px 10px",
//     border: 0,
//   },
//   inputLabel: {
//     backgroundColor: theme?.palette?.background?.paper,
//     padding: "0px 10px 0px 0px",
//   },
// }));
