import { normalizeBatchTransfers } from "./Normalizers";
import useCallAxios from "./useCallAxios";
import React from 'react'

function useReceiveValidateCsvTransfers() {
    const context = `receiveValidateBatchTransfers`;
    const [csvTransfers, setCsvTransfers] = React.useState({
      items: [],
      totalTransfers: 0,
      totalAmount: 0
    });
    const { reset, requestSuccess, requestData, requestLoading, fetchPOST } =
      useCallAxios({
        context,
      });
  
    const sendFile = (file) => {
      const formData = new FormData();
      formData.append('csvFile', file);

      fetchPOST({ context, body: formData, headers: { 'Content-Type': 'multipart/form-data' } });
    };
  
    React.useEffect(() => {
      if (requestData.data) {
        setCsvTransfers(requestData.data);
      }
    }, [requestData]);
  
    return {
      reset,
      loading: requestLoading,
      csvTransfers,
      success: requestSuccess,
      sendFile,
      setCsvTransfers
    };
}

export {
    useReceiveValidateCsvTransfers
}