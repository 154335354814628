import { Paper, useMediaQuery } from "@mui/material";
import {
  Container,
  ContentContainer,
  FilterContainer,
  ListContainer,
  PaperHeader,
} from "./styles";
import { useEffect, useState } from "react";
import intl from "react-intl-universal";
import {
  DateFilter,
  ReceiptList,
  ScreenGreeting,
} from "../../../Components/Molecules";
import { useReceiptsData } from "../../../Hooks";

import { useFilter } from "../../../Hooks/useFilter";
import { TableFilter } from "../../../Components/Molecules/Filters";

export const Receipts = () => {
  const matches = useMediaQuery("(min-width: 1220px)");
  const [page, setPage] = useState(1);

  const {
    state: receiptsData,
    handleGET: fetchReceiptsGET,
    loading,
  } = useReceiptsData();

  const { getFilterObj } = useFilter();
  const extractFilterObj = getFilterObj("receipt");

  const handleSetToFirstPage = () => {
    setPage(1);
  };

  const handleChangePage = (event, value) => {
    setPage(value);
  };

  useEffect(() => {
    const queryParameters = {
      is_paged_result: true,
      page: page,
      page_size: 15,
      type: "all",
      ...extractFilterObj,
    };
    fetchReceiptsGET({
      ...queryParameters,
    });
  }, [extractFilterObj, page]);

  return (
    <Container>
      <ScreenGreeting
        title={intl.get(`words.receipts`)}
        subTitle={intl.get(`phrases.availableReceipts`, {
          total: receiptsData?.rowCount || 0,
        })}
      />
      <FilterContainer>
        <TableFilter
          tableName="receipt"
          setPageToFirst={handleSetToFirstPage}
        />
      </FilterContainer>
      <Paper elevation={3}>
        <ContentContainer>
          <PaperHeader
            style={{
              flexDirection: matches ? "row" : "column",
            }}
          >
            {receiptsData !== undefined && (
              <DateFilter
                tableName={"receipt"}
                setPageToFirst={handleSetToFirstPage}
                hasData={Object.keys(receiptsData).length > 0}
              />
            )}
          </PaperHeader>
          <ListContainer>
            <ReceiptList
              itens={receiptsData}
              loading={loading.GET}
              type={"receipts"}
              title=""
              headerLink={""}
              hasFilter={""}
              handleChangePage={handleChangePage}
              page={page}
            />
          </ListContainer>
        </ContentContainer>
      </Paper>
    </Container>
  );
};
